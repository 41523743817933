<template>
<svg width="38" height="72" viewBox="0 0 38 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_233_68)">
<path d="M13.6661 10.0165L13.7565 8.79968L16.6193 8.56226L16.8302 10.8772C16.8302 10.8772 17.2822 12.0198 16.6193 12.1534C15.9563 12.2869 13.3798 12.183 13.214 11.6191C13.0483 11.0552 13.6661 10.0017 13.6661 10.0017V10.0165Z" fill="#F2F2F2"/>
<path d="M16.1371 11.1295C16.1371 11.1295 16.0467 11.2333 15.9714 11.2482C15.5043 11.4411 14.1482 11.3817 13.9222 11.2482C13.7565 11.1443 13.7263 10.4468 13.7263 10.0759C14.1633 10.7288 14.9016 11.1591 15.7454 11.1591C15.881 11.1591 16.0166 11.1591 16.1371 11.1295Z" fill="#F2F2F2"/>
<path d="M14.9468 18.2671C17.4028 22.8376 24.5749 24.8557 30.195 24.2028L30.5868 26.1616C30.5868 26.1616 19.7684 28.3281 12.5662 22.8673" fill="#DBDBDB"/>
<path d="M20.1302 34.9909C19.9343 35.5845 19.7836 36.1632 19.6932 36.7271C19.2261 39.6505 19.7836 44.8442 18.2769 49.5482C18.2618 49.6076 18.2317 49.6818 18.2015 49.7411C16.4085 54.6084 3.2848 67.1476 3.2848 67.1476L1.2959 65.9308C1.2959 65.9308 8.63373 54.0297 10.0199 50.8986C10.9541 48.7766 8.24197 41.1196 7.45847 35.7923C7.36806 35.2432 7.78995 34.2045 7.74475 33.6703C7.54887 31.2515 8.1365 31.0437 9.4323 28.9811L10.0651 27.972H18.8796C18.8796 27.972 18.9549 32.2754 20.1151 34.9909H20.1302Z" fill="#505C70"/>
<path d="M22.5709 69.418H20.2355C20.2355 69.418 20.0697 55.4988 19.5574 52.1154C19.467 51.4477 18.9547 50.6463 18.2014 49.756C16.107 47.2333 12.0689 43.939 9.52253 40.5557C8.81436 39.6208 8.27193 38.582 7.91031 37.4988C7.53363 36.4007 7.32268 35.258 7.30762 34.1006H19.8437C19.919 34.4122 20.0245 34.709 20.13 34.9909C21.3655 38.2704 24.5899 41.8912 25.8405 46.6843C27.0911 51.4625 22.5709 69.418 22.5709 69.418Z" fill="#657595"/>
<path d="M14.3744 8.87389C11.9636 8.87389 11.4664 7.00414 11.5267 4.27372C11.5267 4.14017 11.5267 4.00662 11.5417 3.87306C11.7376 1.15748 13.3348 0.430359 15.6401 0.430359C17.2071 0.430359 18.2467 1.33555 18.8494 2.73044C19.1809 3.48724 19.3768 4.40728 19.4672 5.38667C19.6932 8.11709 16.7852 8.87389 14.3744 8.87389Z" fill="#F2F2F2"/>
<path d="M11.2403 5.25306C11.2403 5.87631 11.3156 6.49956 11.5266 7.09313C11.7375 7.6867 12.084 8.23575 12.5662 8.62157C12.7169 8.74028 12.8676 8.82932 13.0484 8.87384C13.2292 8.91835 13.4401 8.87384 13.5607 8.72544C13.7415 8.53253 13.6812 8.23575 13.636 7.96864C13.5456 7.37507 13.5456 6.76666 13.636 6.18793C13.6661 6.03954 13.6963 5.90599 13.7415 5.77244C13.9072 5.37178 14.2839 5.08983 14.6756 4.88208C15.0674 4.67433 15.4893 4.54078 15.866 4.31819C16.1673 4.14012 16.4385 3.91753 16.6495 3.65042C16.7248 3.56139 16.8001 3.45751 16.8152 3.35364C16.8303 3.24976 16.8152 3.10137 16.6947 3.04202C16.6344 3.01234 16.5741 2.9975 16.5139 2.9975C16.1673 2.9975 15.8509 3.16073 15.5194 3.2646C14.3592 3.66526 13.0634 3.42784 11.8731 3.73946C11.5416 3.8285 11.3909 3.91753 11.3005 4.22915C11.2101 4.54078 11.1951 4.89692 11.1951 5.23822L11.2403 5.25306Z" fill="#141414"/>
<path d="M21.8627 2.04795L18.6835 2.86411C16.7699 3.428 13.425 3.8435 11.5566 3.94737C11.4662 3.94737 11.3909 3.87318 11.3909 3.78414C11.6621 1.06856 12.5661 0.193043 15.5043 0.000133177C17.674 -0.13342 18.126 1.0834 18.789 2.2557C19.3465 2.10731 20.6573 1.79568 21.4107 1.79568C22.375 1.79568 21.8627 2.03311 21.8627 2.03311V2.04795Z" fill="#FF862B"/>
<path d="M18.2617 4.51114C18.4447 4.51114 18.5931 4.36498 18.5931 4.18468C18.5931 4.00438 18.4447 3.85822 18.2617 3.85822C18.0786 3.85822 17.9302 4.00438 17.9302 4.18468C17.9302 4.36498 18.0786 4.51114 18.2617 4.51114Z" fill="#141414"/>
<path d="M19.4519 6.12861C19.4519 6.12861 19.4369 6.12861 19.4218 6.12861C18.9396 6.15829 18.4575 6.0841 18.0055 5.90603C17.9301 5.87635 17.8548 5.90603 17.8397 5.98022C17.8247 6.05442 17.8397 6.12861 17.9151 6.14345C18.3219 6.30669 18.7739 6.38088 19.2109 6.38088C19.2862 6.38088 19.3465 6.38088 19.4067 6.38088L19.467 6.11378L19.4519 6.12861Z" fill="#1D1D1B"/>
<path d="M34.0119 15.7932L24.1693 18.1329C23.4655 18.3002 23.0327 18.9977 23.2026 19.6908L25.5783 29.3844C25.7482 30.0775 26.4564 30.5038 27.1602 30.3365L37.0028 27.9967C37.7066 27.8294 38.1394 27.1319 37.9695 26.4388L35.5938 16.7452C35.4239 16.0521 34.7157 15.6259 34.0119 15.7932Z" fill="#657595"/>
<path opacity="0.5" d="M28.1855 17.1678L30.2214 16.6839L30.5432 17.9966C30.6351 18.3716 30.4053 18.7621 30.0098 18.8561L29.38 19.0058C28.9992 19.0963 28.6027 18.87 28.5073 18.4805L28.1855 17.1678Z" fill="#1E2D4B"/>
<path d="M35.7176 25.2749L32.4953 26.0409C32.2769 26.0929 32.1426 26.3093 32.1953 26.5244L32.4746 27.664C32.5273 27.8791 32.7471 28.0114 32.9655 27.9595L36.1878 27.1935C36.4062 27.1416 36.5405 26.9251 36.4878 26.71L36.2085 25.5704C36.1558 25.3553 35.936 25.223 35.7176 25.2749Z" fill="white"/>
<path d="M19.874 69.4625V70.7684C19.874 71.0503 20.1 71.2729 20.3862 71.2729H28.4473C28.4473 71.2729 27.6035 69.1212 22.2094 69.1212H20.1904C20.0096 69.1212 19.8589 69.2696 19.8589 69.4477L19.874 69.4625Z" fill="#141414"/>
<path d="M0.78346 65.9901L0.0752914 67.1031C-0.0753826 67.3405 -4.56008e-05 67.6521 0.241033 67.8005L7.08164 72C7.08164 72 7.51859 69.7445 2.9381 66.9398L1.23548 65.8863C1.08481 65.7972 0.873864 65.8417 0.78346 65.9901Z" fill="#141414"/>
<path d="M19.8439 34.1006H7.30779C7.29272 34.0115 7.29272 33.9077 7.29272 33.8186V31.0437C7.29272 28.7288 7.54887 26.4435 8.04609 24.1879C9.05561 19.5284 11.0294 11.9604 13.621 9.9126C13.6512 9.97195 13.6813 10.0313 13.7114 10.0758C14.1484 10.7288 14.8867 11.1591 15.7305 11.1591C15.8661 11.1591 16.0017 11.1443 16.1222 11.1294C16.4085 11.0849 16.6647 10.9959 16.8907 10.8623C18.2317 13.2663 18.759 18.1039 18.8494 22.0214C18.9097 24.7073 18.9097 28.1055 19.2713 31.0437C19.3919 32.1566 19.5727 33.1954 19.8439 34.1006Z" fill="#FF862B"/>
<path d="M31.4005 29.352C31.4005 29.352 31.3101 29.2778 31.2649 29.263C31.1443 29.2185 31.0087 29.2036 30.8731 29.2185C31.0238 29.2482 31.1745 29.2185 31.295 29.1146C31.3402 29.0849 31.3854 29.0256 31.3854 28.9811C31.3854 28.892 31.28 28.8623 31.1895 28.8327C31.0991 28.8178 31.0087 28.7881 30.9183 28.7733C31.0389 28.803 31.1745 28.7733 31.2498 28.6694C31.3252 28.5804 31.3402 28.4172 31.2498 28.343C31.2197 28.3133 31.1745 28.2985 31.1293 28.2836C30.6471 28.1352 30.1348 28.0313 29.6225 27.9571C23.9723 27.5416 17.3275 24.2177 15.8057 19.2614L12.5813 23.3273C18.5932 30.0495 29.6376 29.9604 29.6376 29.9604C30.0595 29.9753 30.6471 30.0346 31.0539 29.9604C31.1443 29.9456 31.2649 29.9011 31.28 29.8121C31.295 29.6933 31.1443 29.634 31.0238 29.6043C31.1293 29.6191 31.2347 29.634 31.3252 29.6043C31.4156 29.5746 31.4909 29.4411 31.4306 29.352H31.4005Z" fill="#F2F2F2"/>
</g>
<defs>
<clipPath id="clip0_233_68">
<rect width="38" height="72" fill="white"/>
</clipPath>
</defs>
</svg>
</template>