<template>
  <div>
    <div class="row p-3">
      <div class="col-12 col-sm-5 pb-4 avatar">
        <img :src="userAvatarUrl" class="img-fluid rounded" :alt="$t('user.avatar')">
      </div>
      <div class="col-12 col-sm-7">
        <h1 class="me-auto">{{ user.name }}</h1>
        <h2 class="py-3">{{ $t("titles.personalDetails") }}</h2>
        <EditableRow :header="$t('user.namePrefix')"
        :body="user.name_prefix"
        />
        <EditableRow :header="$t('user.firstName')"
        :body="user.first_name"
        />
        <EditableRow :header="$t('user.lastName')"
        :body="user.last_name"
        />
        <EditableRow :header="$t('user.nameSuffix')"
        :body="user.name_suffix"
        />
        <EditableRow :header="$t('user.domesticAddress')"
        :body="user.domestic_address"
        />
        <EditableRow :header="$t('user.email')"
        :inputType="'email'"
        :body="user.email"
        />
        <EditableRow :header="$t('user.phone')"
        :body="user.phone"
        />
        <a
          :href="registerUrl + '/member/' + $store.getters.user.register_id" target="_blank"
          class="btn btn-primary text-white mb-5 ms-2"
        >
          {{ $t("user.editProfile") }}
        </a>
        <h2 class="pb-3">{{ $t("titles.functions") }}</h2>
        <ul>
          <li v-for="(func, i) in user.functions" v-key="`Func${i}`">
            {{ $t("functions." + func.name) }}
          </li>
          <li v-if="user.functions && user.functions.length === 0">
            {{ $t("functions.default") }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import EditableRow from "@/components/EditableRow.vue";
import { CONFIG } from "@/config.js";

export default {
  components: {
    EditableRow
  },
  data() {
    return {
      user: {},
      photo: null,
      hrefPhoto: null,
      registerUrl: CONFIG.registerVueAppUrl
    }
  },
  mounted: function () {
    this.user = this.$store.getters.user;
    if (this.user && this.user.avatar) {
      this.downloadPhoto();
    }
  },
  computed: {
    userAvatarUrl: function () {
      if (this.user && this.user.avatar && this.hrefPhoto) {
        return this.hrefPhoto;
      }
      return "https://via.placeholder.com/1000";
    },
  },
  methods: {
    downloadPhoto: function () {
      this.$store.getters.api.get('/auth/avatar',
        {
          responseType: 'blob'
        }).then((response) => {
          this.hrefPhoto = URL.createObjectURL(response.data);
        });
    },
  }
}
</script>

<style lang="scss">

.avatar {
  position: relative;
}

.avatar img {
  width: 100%;
}

.photo-icon {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-32%, 10%);
  -ms-transform: translate(-32%, 10%);
  -webkit-transform: translate(-32%, 10%);
  opacity: 0.4;
}

.photo-icon:hover {
  opacity: 1;
}
</style>