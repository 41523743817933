<template lang="">
 <svg width="340" height="89" viewBox="0 0 340 89" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M110.219 61.3206L339.392 61.3206V64.7182C339.392 65.9883 338.361 67.0195 337.091 67.0195C336.255 67.0195 335.495 67.4646 335.082 68.181L331.685 74.1296C330.99 75.3562 329.687 76.1052 328.276 76.1052H261.68C260.312 76.1052 259.031 75.3888 258.326 74.2164L255.189 69.0277C254.483 67.8553 253.202 67.1389 251.834 67.1389L192.989 67.1389C191.665 67.1389 190.438 67.8011 189.711 68.9083L186.128 74.3467C185.401 75.4539 184.175 76.1161 182.85 76.1161L113.703 76.1161C112.694 76.1161 111.869 75.2911 111.869 74.2816V71.0467C111.869 70.1783 111.163 69.4727 110.295 69.4727H105.258V66.5093H107.896C109.187 66.5093 110.24 65.4564 110.24 64.1646V61.3206H110.219Z" fill="#505C70"/>
<path d="M115.277 75.0089C115.277 75.0089 116.602 62.2433 129.617 62.2433C139.886 62.2433 142.665 69.9721 143.37 73.2395C143.555 74.0754 144.282 74.6724 145.14 74.6724L150.513 74.6941C151.338 74.6941 152.054 74.1405 152.261 73.3372C153.064 70.1024 156.071 62.2541 166.286 62.2541C179.138 62.2541 180.495 76.6263 180.495 76.6263V78.2546C180.495 79.1013 179.811 79.7852 178.964 79.796L116.83 80.1759C115.983 80.1759 115.288 79.4921 115.288 78.6345V75.0198L115.277 75.0089Z" fill="#353C47"/>
<path d="M14.9873 75.2368C14.9873 75.2368 16.3116 62.4712 29.3269 62.4712C39.6284 62.4712 42.3856 70.2543 43.0912 73.5C43.2649 74.325 44.0139 74.9003 44.8497 74.9003L50.223 74.922C51.0589 74.922 51.7753 74.3467 51.9707 73.5434C52.7848 70.2977 55.8025 62.482 65.9846 62.482C78.8371 62.482 80.194 76.8542 80.194 76.8542V80.0131L43.0695 80.1867H14.9873L14.9873 75.2477V75.2368Z" fill="#353C47"/>
<path d="M263.2 75.0414C263.2 75.0414 264.557 62.2758 277.844 62.2758C288.395 62.2758 291.206 70.1024 291.912 73.3263C292.086 74.1296 292.791 74.6941 293.616 74.7049L299.217 74.7375C300.042 74.7375 300.759 74.1947 300.965 73.3915C301.79 70.1566 304.862 62.2758 315.305 62.2758C324.684 62.2758 328.07 69.603 329.243 73.7931C329.644 75.2477 329.829 76.7565 329.829 78.2763C329.829 79.123 329.145 79.8068 328.298 79.8068L266.62 80.1759C264.731 80.1868 263.2 78.6562 263.2 76.7783V75.0414Z" fill="#353C47"/>
<path d="M99.983 66.5093C99.7767 66.8024 66.3429 66.6504 50.3099 66.5527C45.2514 66.531 41.9406 66.5093 41.9406 66.5093C41.9406 66.5093 40.3775 64.1755 36.7085 62.6123C34.8522 61.8199 32.4641 61.212 29.479 61.212C20.5669 61.212 17.3429 66.5093 17.3429 66.5093H3.9043L3.94772 65.8363L4.07798 63.3613L4.17567 61.3857C4.19739 60.8864 4.26252 60.387 4.37107 59.9094C4.41449 59.6814 4.46876 59.4535 4.53389 59.2255C4.56646 59.0844 4.60988 58.9541 4.6533 58.8239C5.04408 57.6732 5.65197 56.6203 6.43354 55.7193C7.59504 54.3407 9.15817 53.3095 10.9601 52.7776C19.7745 50.1615 34.7111 45.6783 36.4154 44.6362C36.4154 44.6362 36.4371 44.6145 36.4588 44.6145L36.4805 44.5711C38.0436 43.4747 40.4969 36.9508 42.0383 32.4568C42.7656 30.3726 44.459 28.8312 46.5432 28.3319C47.0643 28.1799 47.6179 28.1039 48.1606 28.1039C48.1606 28.1039 48.5623 25.7701 50.8961 22.7306C53.23 19.7237 57.496 16.0004 65.2032 13.265C78.2619 8.60811 94.1538 7.75055 98.7129 7.58773C99.4185 7.56602 100.005 8.10877 100.005 8.81435C100.005 11.7235 100.037 19.333 100.059 28.1039C100.124 45.027 100.157 66.2705 99.983 66.5093Z" fill="#FF862B"/>
<path d="M110.219 5.63381L110.219 61.3206L339.393 61.3206V5.63381C339.393 2.51839 336.874 0 333.759 0L115.853 0C112.738 0 110.219 2.51839 110.219 5.63381Z" fill="#657595"/>
<path d="M0.679913 69.0494L0.679913 75.4214C0.679913 78.0483 2.80752 80.1759 5.43446 80.1759H15.7034C15.7034 80.1759 13.2067 63.0574 29.446 63.0574C45.6852 63.0574 43.0583 80.1759 43.0583 80.1759L105.888 80.1759V40.2834C105.888 38.438 104.39 36.94 102.544 36.94H100.102L100.004 63.991C100.004 65.261 98.9622 66.2922 97.6921 66.2922L44.0244 66.3357C42.8521 66.3357 41.7014 65.9232 40.8547 65.109C39.2373 63.5459 35.6768 61.0275 29.4677 61.0275C23.2586 61.0275 19.9369 63.4808 18.3846 65.0548C17.5705 65.8906 16.4524 66.3465 15.28 66.3574L3.1983 66.4876C1.79799 66.4985 0.658203 67.6491 0.658203 69.0494H0.679913Z" fill="#505C70"/>
<path d="M108.482 69.0385H82.0279C80.7809 69.0385 79.77 70.0494 79.77 71.2964V80.6752C79.77 81.9222 80.7809 82.9331 82.0279 82.9331H108.482C109.729 82.9331 110.74 81.9222 110.74 80.6752V71.2964C110.74 70.0494 109.729 69.0385 108.482 69.0385Z" fill="#FF862B"/>
<path d="M75.6349 69.0385H65.2357C64.0247 69.0385 63.043 70.0202 63.043 71.2313V80.7403C63.043 81.9513 64.0247 82.9331 65.2357 82.9331H75.6349C76.8459 82.9331 77.8276 81.9513 77.8276 80.7403V71.2313C77.8276 70.0202 76.8459 69.0385 75.6349 69.0385Z" fill="#FF862B"/>
<path d="M29.5976 88.86C36.3601 88.86 41.8422 83.3779 41.8422 76.6154C41.8422 69.8529 36.3601 64.3708 29.5976 64.3708C22.8351 64.3708 17.353 69.8529 17.353 76.6154C17.353 83.3779 22.8351 88.86 29.5976 88.86Z" fill="black"/>
<path d="M29.598 81.8585C32.4936 81.8585 34.841 79.5111 34.841 76.6155C34.841 73.7198 32.4936 71.3724 29.598 71.3724C26.7024 71.3724 24.355 73.7198 24.355 76.6155C24.355 79.5111 26.7024 81.8585 29.598 81.8585Z" fill="#FF862B"/>
<path d="M129.617 88.86C136.38 88.86 141.862 83.3779 141.862 76.6154C141.862 69.8529 136.38 64.3708 129.617 64.3708C122.855 64.3708 117.373 69.8529 117.373 76.6154C117.373 83.3779 122.855 88.86 129.617 88.86Z" fill="black"/>
<path d="M166.275 88.86C173.037 88.86 178.519 83.3779 178.519 76.6154C178.519 69.8529 173.037 64.3708 166.275 64.3708C159.512 64.3708 154.03 69.8529 154.03 76.6154C154.03 83.3779 159.512 88.86 166.275 88.86Z" fill="black"/>
<path d="M278.202 88.86C284.964 88.86 290.446 83.3779 290.446 76.6154C290.446 69.8529 284.964 64.3708 278.202 64.3708C271.439 64.3708 265.957 69.8529 265.957 76.6154C265.957 83.3779 271.439 88.86 278.202 88.86Z" fill="black"/>
<path d="M314.859 88.86C321.622 88.86 327.104 83.3779 327.104 76.6154C327.104 69.8529 321.622 64.3708 314.859 64.3708C308.097 64.3708 302.615 69.8529 302.615 76.6154C302.615 83.3779 308.097 88.86 314.859 88.86Z" fill="black"/>
<path d="M85.6102 70.8839H82.7662C82.6583 70.8839 82.5708 70.9714 82.5708 71.0793V81.0443C82.5708 81.1522 82.6583 81.2397 82.7662 81.2397H85.6102C85.7181 81.2397 85.8056 81.1522 85.8056 81.0443V71.0793C85.8056 70.9714 85.7181 70.8839 85.6102 70.8839Z" fill="#CE6B29"/>
<path d="M90.8969 70.8839H88.0528C87.9449 70.8839 87.8574 70.9714 87.8574 71.0793V81.0443C87.8574 81.1522 87.9449 81.2397 88.0528 81.2397H90.8969C91.0048 81.2397 91.0923 81.1522 91.0923 81.0443V71.0793C91.0923 70.9714 91.0048 70.8839 90.8969 70.8839Z" fill="#CE6B29"/>
<path d="M69.2084 70.8839H66.3643C66.2564 70.8839 66.1689 70.9714 66.1689 71.0793V81.0443C66.1689 81.1522 66.2564 81.2397 66.3643 81.2397H69.2084C69.3163 81.2397 69.4038 81.1522 69.4038 81.0443V71.0793C69.4038 70.9714 69.3163 70.8839 69.2084 70.8839Z" fill="#CE6B29"/>
<path d="M74.495 70.8839H71.651C71.543 70.8839 71.4556 70.9714 71.4556 71.0793V81.0443C71.4556 81.1522 71.543 81.2397 71.651 81.2397H74.495C74.6029 81.2397 74.6904 81.1522 74.6904 81.0443V71.0793C74.6904 70.9714 74.6029 70.8839 74.495 70.8839Z" fill="#CE6B29"/>
<path d="M96.7367 70.8839H93.8927C93.7847 70.8839 93.6973 70.9714 93.6973 71.0793V81.0443C93.6973 81.1522 93.7847 81.2397 93.8927 81.2397H96.7367C96.8446 81.2397 96.9321 81.1522 96.9321 81.0443V71.0793C96.9321 70.9714 96.8446 70.8839 96.7367 70.8839Z" fill="#CE6B29"/>
<path d="M102.306 70.8839H99.4615C99.3536 70.8839 99.2661 70.9714 99.2661 71.0793V81.0443C99.2661 81.1522 99.3536 81.2397 99.4615 81.2397H102.306C102.413 81.2397 102.501 81.1522 102.501 81.0443V71.0793C102.501 70.9714 102.413 70.8839 102.306 70.8839Z" fill="#CE6B29"/>
<path d="M107.581 70.8839H104.737C104.629 70.8839 104.542 70.9714 104.542 71.0793V81.0443C104.542 81.1522 104.629 81.2397 104.737 81.2397H107.581C107.689 81.2397 107.777 81.1522 107.777 81.0443V71.0793C107.777 70.9714 107.689 70.8839 107.581 70.8839Z" fill="#CE6B29"/>
<path d="M45.4031 33.5749L40.9742 42.7583C40.8222 43.0731 41.0502 43.4314 41.3975 43.4314H61.0887C61.3927 43.4314 61.6315 43.1925 61.6315 42.8886V32.1095C61.6315 31.8055 61.3927 31.5667 61.0887 31.5667L48.6271 31.5667C47.2593 31.5667 46.011 32.3483 45.4139 33.5858L45.4031 33.5749Z" fill="#505C70"/>
<path d="M78.0877 31.5558H69.1214C68.9775 31.5558 68.8608 31.6725 68.8608 31.8164V43.16C68.8608 43.3039 68.9775 43.4205 69.1214 43.4205H78.0877C78.2316 43.4205 78.3482 43.3039 78.3482 43.16V31.8164C78.3482 31.6725 78.2316 31.5558 78.0877 31.5558Z" fill="#505C70"/>
<path d="M129.617 81.8585C132.513 81.8585 134.86 79.5111 134.86 76.6155C134.86 73.7198 132.513 71.3724 129.617 71.3724C126.721 71.3724 124.374 73.7198 124.374 76.6155C124.374 79.5111 126.721 81.8585 129.617 81.8585Z" fill="#FF862B"/>
<path d="M166.275 81.8585C169.171 81.8585 171.518 79.5111 171.518 76.6155C171.518 73.7198 169.171 71.3724 166.275 71.3724C163.38 71.3724 161.032 73.7198 161.032 76.6155C161.032 79.5111 163.38 81.8585 166.275 81.8585Z" fill="#FF862B"/>
<path d="M278.202 81.8585C281.098 81.8585 283.445 79.5111 283.445 76.6155C283.445 73.7198 281.098 71.3724 278.202 71.3724C275.306 71.3724 272.959 73.7198 272.959 76.6155C272.959 79.5111 275.306 81.8585 278.202 81.8585Z" fill="#FF862B"/>
<path d="M314.86 81.8585C317.755 81.8585 320.103 79.5111 320.103 76.6155C320.103 73.7198 317.755 71.3724 314.86 71.3724C311.964 71.3724 309.617 73.7198 309.617 76.6155C309.617 79.5111 311.964 81.8585 314.86 81.8585Z" fill="#FF862B"/>
<path d="M60.2853 45.7977H53.7288C53.2492 45.7977 52.8604 46.1865 52.8604 46.6661V48.5549C52.8604 49.0345 53.2492 49.4233 53.7288 49.4233H60.2853C60.7649 49.4233 61.1537 49.0345 61.1537 48.5549V46.6661C61.1537 46.1865 60.7649 45.7977 60.2853 45.7977Z" fill="#CE6B29"/>
<path d="M93.8384 34.3022C93.8384 32.7854 92.6088 31.5558 91.092 31.5558C89.5753 31.5558 88.3457 32.7854 88.3457 34.3022V59.3775C88.3457 60.8943 89.5753 62.1239 91.092 62.1239C92.6088 62.1239 93.8384 60.8943 93.8384 59.3775V34.3022Z" fill="#CE6B29"/>
<path d="M126.751 9.66108C126.751 8.14431 125.522 6.91473 124.005 6.91473C122.488 6.91473 121.259 8.14431 121.259 9.66108L121.259 53.4398C121.259 54.9565 122.488 56.1861 124.005 56.1861C125.522 56.1861 126.751 54.9565 126.751 53.4398V9.66108Z" fill="#162330"/>
<path d="M134.99 9.66108C134.99 8.14431 133.761 6.91473 132.244 6.91473C130.727 6.91473 129.498 8.14431 129.498 9.66108V53.4398C129.498 54.9565 130.727 56.1861 132.244 56.1861C133.761 56.1861 134.99 54.9565 134.99 53.4398V9.66108Z" fill="#162330"/>
<path d="M143.24 9.66108C143.24 8.14431 142.011 6.91473 140.494 6.91473C138.977 6.91473 137.748 8.14431 137.748 9.66108V53.4398C137.748 54.9565 138.977 56.1861 140.494 56.1861C142.011 56.1861 143.24 54.9565 143.24 53.4398V9.66108Z" fill="#162330"/>
<path d="M151.49 9.66108C151.49 8.14431 150.261 6.91473 148.744 6.91473C147.227 6.91473 145.998 8.14431 145.998 9.66108V53.4398C145.998 54.9565 147.227 56.1861 148.744 56.1861C150.261 56.1861 151.49 54.9565 151.49 53.4398V9.66108Z" fill="#162330"/>
<path d="M159.729 9.66108C159.729 8.14431 158.499 6.91473 156.983 6.91473C155.466 6.91473 154.236 8.14431 154.236 9.66108V53.4398C154.236 54.9565 155.466 56.1861 156.983 56.1861C158.499 56.1861 159.729 54.9565 159.729 53.4398V9.66108Z" fill="#162330"/>
<path d="M167.979 9.66108C167.979 8.14431 166.749 6.91473 165.233 6.91473C163.716 6.91473 162.486 8.14431 162.486 9.66108V53.4398C162.486 54.9565 163.716 56.1861 165.233 56.1861C166.749 56.1861 167.979 54.9565 167.979 53.4398V9.66108Z" fill="#162330"/>
<path d="M176.229 9.66108C176.229 8.14431 174.999 6.91473 173.483 6.91473C171.966 6.91473 170.736 8.14431 170.736 9.66108V53.4398C170.736 54.9565 171.966 56.1861 173.483 56.1861C174.999 56.1861 176.229 54.9565 176.229 53.4398V9.66108Z" fill="#162330"/>
<path d="M184.468 9.66108C184.468 8.14431 183.238 6.91473 181.721 6.91473C180.205 6.91473 178.975 8.14431 178.975 9.66108V53.4398C178.975 54.9565 180.205 56.1861 181.721 56.1861C183.238 56.1861 184.468 54.9565 184.468 53.4398V9.66108Z" fill="#162330"/>
<path d="M192.718 9.66108C192.718 8.14431 191.488 6.91473 189.971 6.91473C188.455 6.91473 187.225 8.14431 187.225 9.66108V53.4398C187.225 54.9565 188.455 56.1861 189.971 56.1861C191.488 56.1861 192.718 54.9565 192.718 53.4398V9.66108Z" fill="#162330"/>
<path d="M200.968 9.66108C200.968 8.14431 199.738 6.91473 198.221 6.91473C196.705 6.91473 195.475 8.14431 195.475 9.66108V53.4398C195.475 54.9565 196.705 56.1861 198.221 56.1861C199.738 56.1861 200.968 54.9565 200.968 53.4398V9.66108Z" fill="#162330"/>
<path d="M209.217 9.66108C209.217 8.14431 207.988 6.91473 206.471 6.91473C204.954 6.91473 203.725 8.14431 203.725 9.66108V53.4398C203.725 54.9565 204.954 56.1861 206.471 56.1861C207.988 56.1861 209.217 54.9565 209.217 53.4398V9.66108Z" fill="#162330"/>
<path d="M217.457 9.66108C217.457 8.14431 216.227 6.91473 214.71 6.91473C213.193 6.91473 211.964 8.14431 211.964 9.66108V53.4398C211.964 54.9565 213.193 56.1861 214.71 56.1861C216.227 56.1861 217.457 54.9565 217.457 53.4398V9.66108Z" fill="#162330"/>
<path d="M225.707 9.66108C225.707 8.14431 224.477 6.91473 222.96 6.91473C221.443 6.91473 220.214 8.14431 220.214 9.66108V53.4398C220.214 54.9565 221.443 56.1861 222.96 56.1861C224.477 56.1861 225.707 54.9565 225.707 53.4398V9.66108Z" fill="#162330"/>
<path d="M233.956 9.66108C233.956 8.14431 232.726 6.91473 231.21 6.91473C229.693 6.91473 228.463 8.14431 228.463 9.66108V53.4398C228.463 54.9565 229.693 56.1861 231.21 56.1861C232.726 56.1861 233.956 54.9565 233.956 53.4398V9.66108Z" fill="#162330"/>
<path d="M242.196 9.66108C242.196 8.14431 240.966 6.91473 239.449 6.91473C237.933 6.91473 236.703 8.14431 236.703 9.66108V53.4398C236.703 54.9565 237.933 56.1861 239.449 56.1861C240.966 56.1861 242.196 54.9565 242.196 53.4398V9.66108Z" fill="#162330"/>
<path d="M250.445 9.66108C250.445 8.14431 249.216 6.91473 247.699 6.91473C246.182 6.91473 244.953 8.14431 244.953 9.66108V53.4398C244.953 54.9565 246.182 56.1861 247.699 56.1861C249.216 56.1861 250.445 54.9565 250.445 53.4398V9.66108Z" fill="#162330"/>
<path d="M258.695 9.66108C258.695 8.14431 257.466 6.91473 255.949 6.91473C254.432 6.91473 253.203 8.14431 253.203 9.66108V53.4398C253.203 54.9565 254.432 56.1861 255.949 56.1861C257.466 56.1861 258.695 54.9565 258.695 53.4398V9.66108Z" fill="#162330"/>
<path d="M266.935 9.66108C266.935 8.14431 265.705 6.91473 264.188 6.91473C262.671 6.91473 261.442 8.14431 261.442 9.66108V53.4398C261.442 54.9565 262.671 56.1861 264.188 56.1861C265.705 56.1861 266.935 54.9565 266.935 53.4398V9.66108Z" fill="#162330"/>
<path d="M275.184 9.66108C275.184 8.14431 273.955 6.91473 272.438 6.91473C270.921 6.91473 269.691 8.14431 269.691 9.66108V53.4398C269.691 54.9565 270.921 56.1861 272.438 56.1861C273.955 56.1861 275.184 54.9565 275.184 53.4398V9.66108Z" fill="#162330"/>
<path d="M283.434 9.66108C283.434 8.14431 282.205 6.91473 280.688 6.91473C279.171 6.91473 277.941 8.14431 277.941 9.66108V53.4398C277.941 54.9565 279.171 56.1861 280.688 56.1861C282.205 56.1861 283.434 54.9565 283.434 53.4398V9.66108Z" fill="#162330"/>
<path d="M291.684 9.66108C291.684 8.14431 290.455 6.91473 288.938 6.91473C287.421 6.91473 286.191 8.14431 286.191 9.66108V53.4398C286.191 54.9565 287.421 56.1861 288.938 56.1861C290.455 56.1861 291.684 54.9565 291.684 53.4398V9.66108Z" fill="#162330"/>
<path d="M299.923 9.66108C299.923 8.14431 298.693 6.91473 297.177 6.91473C295.66 6.91473 294.43 8.14431 294.43 9.66108V53.4398C294.43 54.9565 295.66 56.1861 297.177 56.1861C298.693 56.1861 299.923 54.9565 299.923 53.4398V9.66108Z" fill="#162330"/>
<path d="M308.173 9.66108C308.173 8.14431 306.943 6.91473 305.427 6.91473C303.91 6.91473 302.68 8.14431 302.68 9.66108V53.4398C302.68 54.9565 303.91 56.1861 305.427 56.1861C306.943 56.1861 308.173 54.9565 308.173 53.4398V9.66108Z" fill="#162330"/>
<path d="M316.423 9.66108C316.423 8.14431 315.193 6.91473 313.677 6.91473C312.16 6.91473 310.93 8.14431 310.93 9.66108V53.4398C310.93 54.9565 312.16 56.1861 313.677 56.1861C315.193 56.1861 316.423 54.9565 316.423 53.4398V9.66108Z" fill="#162330"/>
<path d="M324.662 9.66108C324.662 8.14431 323.432 6.91473 321.915 6.91473C320.399 6.91473 319.169 8.14431 319.169 9.66108V53.4398C319.169 54.9565 320.399 56.1861 321.915 56.1861C323.432 56.1861 324.662 54.9565 324.662 53.4398V9.66108Z" fill="#162330"/>
<path d="M11.2959 57.4779C11.2959 58.444 10.5144 59.2256 9.54827 59.2256H4.5332C4.56577 59.0844 4.60919 58.9542 4.65261 58.8239C5.04339 57.6733 5.65128 56.6203 6.43285 55.7194H9.54827C10.5144 55.7194 11.2959 56.5009 11.2959 57.4779Z" fill="#E07228"/>
<path d="M6.83484 61.6571C6.83484 62.6232 6.05327 63.4048 5.08716 63.4048H4.45757C4.3273 63.4048 4.19704 63.3939 4.07764 63.3613L4.17533 61.3857C4.19704 60.8864 4.26217 60.387 4.37072 59.9094C4.39244 59.8986 4.425 59.8986 4.45757 59.8986H5.08716C6.05327 59.8986 6.83484 60.6801 6.83484 61.6571Z" fill="#E07228"/>
<path d="M97.6274 28.1039L48.1606 28.1039C48.1606 28.1039 48.5623 25.7701 50.8961 22.7306C53.23 19.7238 57.496 16.0004 65.2032 13.265C78.2619 8.60811 94.1538 7.75055 98.7129 7.58773C99.4185 7.56602 100.005 8.10877 100.005 8.81435C100.005 11.4521 100.037 17.9435 100.048 25.6724C100.048 27.0184 98.9626 28.1039 97.6274 28.1039Z" fill="#CE6B29"/>
<path d="M161.39 18.8011V37.8301H156.114L148.885 29.1894V37.8301H142.632V18.8011H147.908L155.137 27.4526V18.8011H161.39Z" fill="#FF862B"/>
<path d="M180.321 32.9995V37.8409H164.495V18.8119L179.963 18.8119V23.6533L170.801 23.6533V25.8786L178.845 25.8786V30.5029H170.801V32.9995H180.321Z" fill="#FF862B"/>
<path d="M181.613 36.3103L183.676 31.6318C185.467 32.6956 187.866 33.3686 189.961 33.3686C191.785 33.3686 192.436 32.9887 192.436 32.3374C192.436 29.9492 181.917 31.8706 181.917 24.7822C181.917 21.2434 184.881 18.3668 190.786 18.3668C193.337 18.3668 195.975 18.9096 197.961 20.0277L196.029 24.6736C194.151 23.7184 192.382 23.2625 190.732 23.2625C188.854 23.2625 188.257 23.8052 188.257 24.4565C188.257 26.7361 198.775 24.8365 198.775 31.8489C198.775 35.3334 195.812 38.2643 189.907 38.2643C186.726 38.2643 183.513 37.4718 181.613 36.3103Z" fill="white"/>
<path d="M218.52 25.9871C218.52 30.3943 215.145 33.1624 209.847 33.1624H207.101V37.8409H200.686V18.8119H209.847C215.145 18.8119 218.52 21.5908 218.52 25.9871ZM212.051 25.9871C212.051 24.5977 211.204 23.7618 209.446 23.7618H207.112V28.2233H209.446C211.215 28.2233 212.051 27.3874 212.051 25.998V25.9871Z" fill="white"/>
<path d="M236.442 32.9995V37.8409H220.615V18.8119L236.084 18.8119V23.6533L226.922 23.6533V25.8786L234.966 25.8786V30.5029H226.922V32.9995H236.442Z" fill="white"/>
<path d="M238.928 18.8011H248.307C254.668 18.8011 259.043 22.4158 259.043 28.321C259.043 34.2262 254.668 37.841 248.307 37.841H238.928V18.8119V18.8011ZM248.036 32.8259C250.728 32.8259 252.573 31.2193 252.573 28.3102C252.573 25.401 250.728 23.7944 248.036 23.7944H245.344V32.815H248.036V32.8259Z" fill="white"/>
<path d="M261.398 18.8011H267.814V37.8301H261.398V18.8011Z" fill="white"/>
<path d="M270.158 28.321C270.158 22.4701 274.641 18.3668 280.764 18.3668C284.487 18.3668 287.429 19.7237 289.296 22.177L285.247 25.7917C284.129 24.3806 282.804 23.5664 281.089 23.5664C278.43 23.5664 276.628 25.4118 276.628 28.321C276.628 31.2301 278.419 33.0755 281.089 33.0755C282.804 33.0755 284.14 32.2614 285.247 30.8502L289.296 34.465C287.418 36.9074 284.487 38.2751 280.764 38.2751C274.641 38.2751 270.158 34.1719 270.158 28.321Z" fill="white"/>
<path d="M306.979 32.9995V37.8409H291.152V18.8119L306.62 18.8119V23.6533L297.459 23.6533V25.8786L305.502 25.8786V30.5029H297.459V32.9995H306.979Z" fill="white"/>
</svg>

</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>