// common configuration
const config = {
    api: {
        baseUrl: "https://volby.svobodni.cz/api",
        registerUrl: "https://registr.svobodni.cz"
    },
    afterLoginRoute: "dashboard",
    vueAppUrl: "https://volby.svobodni.cz",
    registerVueAppUrl: "https://registr.svobodni.cz/app",
    registerOauthClientId: "A2HqajpH8n5sqDz7rEhtvIFnRwrmDo40iilyPuot"
};

if (import.meta.env.VITE_VUE_APP_SERVER == "production") {
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dev_matej") {
    config["vueAppUrl"] = "http://localhost:10101";
    config["registerVueAppUrl"] = "https://staging.registr.svobodni.cz/app";
    config["api"]["baseUrl"] = "http://localhost:10100";
    config["api"]["registerUrl"] = "https://staging.registr.svobodni.cz";
    config["registerOauthClientId"] = "A2HqajpH8n5sqDz7rEhtvIFnRwrmDo40iilyPuot";
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dev_michal") {
    config["vueAppUrl"] = "http://localhost:35003";
    config["registerVueAppUrl"] = "http://localhost:35001";
    config["api"]["baseUrl"] = "http://localhost:35004";
    config["api"]["registerUrl"] = "http://localhost:35002";
    config["registerOauthClientId"] = "CK8UE3yPv9Cc4jad0hNf5PfRAqIxZqoszxdAolJ6";
}

export const CONFIG = config;