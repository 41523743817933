<template>
  <div class="vertical-nav bg-main" :class="{active: isActive}" id="sidebar">
    <!-- <router-link class="nav-link px-4 text-nowrap" to="/profile">
      {{ $t("navbar.myProfile") }}
    </router-link>
    <router-link class="nav-link px-4 text-nowrap" to="/logout">
      {{ $t("navbar.logout") }}
    </router-link>
    <ChangeLang class="mx-4" /> -->
  </div>
  <div class="page-content p-3" :class="{active: isActive}" id="content">
    <button id="sidebarCollapse" type="button" class="btn btn-light bg-primary shadow-sm px-4 mb-4" @click="toggleMenu()">
      <small class="text-uppercase font-weight-bold">Toggle</small>
    </button>
  </div>
</template>


<script>
import ChangeLang from "@/components/ChangeLang.vue"
export default {
  components: {
    ChangeLang
  },
  data() {
    return {
      isActive: false
    }
  },
  methods: {
    // Sidebar toggle behavior
    toggleMenu() {
      this.isActive = !this.isActive;
    }
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/variables";

.vertical-nav {
  min-width: 17rem;
  width: 17rem;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
}

.page-content {
  width: calc(100% - 17rem);
  margin-left: 17rem;
  transition: all 0.4s;
}

/* for toggle behavior */

#sidebar.active {
  margin-left: -17rem;
}

#content.active {
  width: 100%;
  margin: 0;
}

@media (max-width: 768px) {
  #sidebar {
    margin-left: -17rem;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #content {
    width: 100%;
    margin: 0;
  }
  #content.active {
    margin-left: 17rem;
    width: calc(100% - 17rem);
  }
}
</style>