<template>
    <svg width="39" height="48" viewBox="0 0 39 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.7892 6C15.0445 6 9.58091 11.3701 9.58091 17.9903C9.58091 21.2726 10.9212 24.2484 13.0986 26.4102C13.0986 26.4102 13.0986 26.4129 13.0932 26.4155L5.43344 37.9671C4.6287 39.1749 4.97859 40.7896 6.20587 41.5772C6.65534 41.8599 7.15594 42 7.65385 42C8.52318 42 9.37636 41.5824 9.88235 40.816L17.5636 29.2406C18.8797 29.719 20.3035 29.9806 21.7892 29.9806C28.5312 29.9806 34.0001 24.6131 34.0001 17.9903C34.0001 11.3675 28.5312 6 21.7892 6ZM21.7892 25.4509C20.7584 25.4509 19.776 25.25 18.8797 24.8827C18.0346 24.5391 17.2649 24.0502 16.6055 23.445C15.1198 22.0813 14.1913 20.1442 14.1913 17.9903C14.1913 13.8702 17.5933 10.5297 21.7892 10.5297C25.9851 10.5297 29.387 13.8702 29.387 17.9903C29.387 22.1104 25.9878 25.4509 21.7892 25.4509Z" fill="#F5F5F5"/>
    <path d="M17.5636 29.2406L9.88235 40.816C9.37636 41.5824 8.52318 42 7.65385 42C7.15594 42 6.65534 41.8599 6.20587 41.5772C4.97859 40.7896 4.6287 39.1749 5.43344 37.9671L13.0932 26.4155L13.0986 26.4102C14.3662 27.5783 15.8331 28.5165 17.4318 29.1851L17.5636 29.2406Z" fill="#1E2D4B"/>
    </svg>
  </template>
    
  <script>
    export default {}
  </script>
    
  <style>
   
  </style>