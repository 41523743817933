<template>
    <div>
      <div class="row p-4">
        <div class="col-12">
          <div class="row">
            <h1 class="mb-4">{{ $t("dashboard.title") }}</h1>
            <div class="col-12 mb-4">
              <router-link to="/dashboard">{{ $t("dashboard.elections") }}</router-link>
              <span v-if="names">
                <template v-if="names && names.current">
                  <span> > </span>
                  <router-link :to="{name: 'election', params: {uuid: names.current.slug}}">{{ names.current.name }}</router-link>
                </template>
              </span>
            </div>
            <div class="d-flex mb-4">
              <router-link v-if="$store.getters.hasPerm('can_manage_elections')" class="btn btn-primary text-white" :to="{name: 'election-members', params: {uuid: $route.params.uuid}}">
                {{ $t("dashboard.searchMembers") }}
              </router-link>
            </div>
            <ElectionsDetail v-if="names && names.current" :item="names.current" :model="modelParent" />
            <h2 class="mb-4" v-if="model">{{ this.$t(`dashboard.title${model}`) }}</h2>
          </div>
          <ElectionsList :items="items" :model="model" :loading="loading" @loadData="loadData" />
        </div>
      </div>
    </div>
  </template>

  <script>
  import Members from "@/components/Members.vue"
  import ElectionsList from "@/components/ElectionsList.vue"
  import ElectionsDetail from "@/components/ElectionsDetail.vue";

  export default {
    components: {
      Members,
      ElectionsList,
      ElectionsDetail,
    },
    data() {
      return {
        items: [],
        model: "",
        modelParent: "Election",
        loading: false,
        names: null,
      }
    },
    created() {
      this.loadData();
    },
    watch: {
      "$route.params": {
        handler: function (val) {
          this.loadData();
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      loadData() {
        this.loading = true;
        this.$store.getters.api.get('/list', { params: { model: this.modelParent, parent: this.$route.params.uuid } }).then((response) => {
          this.items = response.data.items;
          this.model = response.data.model;
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
          if (error.response && error.response.data && error.response.data.error_code) {
            alert(this.$t("error." + error.response.data.error_code));
          }
          else {
            alert(this.$t("error.undefined" + ": " + error));
          }
        });
        this.$store.getters.api.get('/parents', { params: { model: this.modelParent, parent: this.$route.params.uuid } }).then((response) => {
          this.names = response.data.data;
        }).catch((error) => {
          if (error.response && error.response.data && error.response.data.error_code) {
            alert(this.$t("error." + error.response.data.error_code));
          }
          else {
            alert(this.$t("error.undefined" + ": " + error));
          }
        });
      },
    }
  }
  </script>

  <style lang="scss">
  </style>