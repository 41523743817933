<template>
  <div>
    <div class="row p-3">
      <div class="col-12">
        <h1>{{ $t("titles.addElections") }}</h1>
        <h4 class="mt-4">{{ $t("elections.databoxTitle") }}</h4>
        <p>{{ $t("elections.databoxDescription") }}</p>
        <button v-if="databoxesUpdated" class="btn btn-success my-3 text-white" disabled>
          {{ $t("elections.databoxUpdated") }}
        </button>
        <button v-else class="btn btn-warning my-3" @click="updateDataboxes()" :disabled="databoxesUpdateRunning">
          {{ databoxesUpdateRunning ? $t("elections.databoxUpdating") : $t("elections.databoxTitle") }}
        </button>
        <h4 class="mt-5">{{ $t("elections.title") }}</h4>
        <input type="text" class="form-control" v-model="title">
        <h4 class="mt-4">{{ $t("elections.dateFrom") }}</h4>
        <input type="date" class="form-control" v-model="dateFrom">
        <h4 class="mt-4">{{ $t("elections.dateTo") }}</h4>
        <input type="date" class="form-control" v-model="dateTo">
        <h4 class="mt-4">{{ $t("elections.dateOpening") }}</h4>
        <input type="date" class="form-control" v-model="dateOpening">
        <h4 class="mt-4">{{ $t("elections.dateClosing") }}</h4>
        <input type="date" class="form-control" v-model="dateClosing">
        <h4 class="mt-5">{{ $t("elections.cities") }}</h4>
        <p>{{ $t("elections.uploadCSVFiles") }}</p>
        <input type="file" class="form-control" accept=".csv" @change="changecitiesFile">
        <h4 class="mt-4">{{ $t("elections.regions") }}</h4>
        <p>{{ $t("elections.uploadCSVFiles") }}</p>
        <input type="file" class="form-control" accept=".csv" @change="changeRegionsFile">
        <h4 class="mt-4 mb-2">{{ $t("elections.districts") }}</h4>
        <p class="mb-2">{{ $t("utils.or") }}</p>
        <h4 class="mt-2">{{ $t("elections.districtsObce") }}</h4>
        <p>{{ $t("elections.uploadCSVFiles") }}</p>
        <input type="file" class="form-control" accept=".csv" @change="changeDistrictsFile">
        <button class="btn btn-lg btn-primary mt-5 text-white" @click="saveData()" :disabled="!databoxesUpdated">
          {{ $t("utils.save") }}
        </button>
        <h3 class="mt-5">{{ $t("elections.whereDownload") }}</h3>
        <p class="mb-1">{{ $t("elections.whereDownloadText1") }}
          <a href="https://apl2.czso.cz/iSMS/cisdata.jsp?kodcis=65" target="_blank">
            https://apl2.czso.cz/iSMS/cisdata.jsp?kodcis=65
          </a>
        </p>
        <p class="mb-1">{{ $t("elections.whereDownloadText2") }}
          <a href="https://www.volby.cz/opendata/opendata.htm" target="_blank">
            https://www.volby.cz/opendata/opendata.htm
          </a>
        </p>
        <p class="mb-1">{{ $t("elections.whereDownloadText3") }}</p>
        <p class="mb-1">{{ $t("elections.whereDownloadText4") }}</p>
      </div>
    </div>
    <LoadingSpinner :loading="loading"/>
  </div>
</template>

<script>
import EditableRow from "@/components/EditableRow.vue"
import LoadingSpinner from '@/components/LoadingSpinner.vue';

export default {
  components: {
    EditableRow,
    LoadingSpinner
  },
  data() {
    return {
      title: "",
      dateFrom: "",
      dateTo: "",
      dateOpening: "",
      dateClosing: "",
      regionsFile: null,
      citiesFile: null,
      districtsFile: null,
      loading: false,
      databoxesUpdated: false,
      databoxesUpdateRunning: false,
    }
  },
  methods: {
    changeRegionsFile(event) {
      this.regionsFile = event.target.files[0];
    },
    changecitiesFile(event) {
      this.citiesFile = event.target.files[0];
    },
    changeDistrictsFile(event) {
      this.districtsFile = event.target.files[0];
    },
    updateDataboxes() {
      this.databoxesUpdateRunning = true;
      this.$store.getters.api.get('/databoxes').then((response) => {
        this.databoxesUpdated = true;
        this.databoxesUpdateRunning = false;
      }).catch((error) => {
        this.databoxesUpdateRunning = false;
        if (error.response && error.response.data && error.response.data.error_code) {
          alert(this.$t("error." + error.response.data.error_code));
        }
        else {
          alert(this.$t("error.undefined" + ": " + error));
        }
      });
    },
    saveData() {
      if (!this.title) {
        alert(this.$t("elections.enterTitle"));
        return;
      }
      if (!this.regionsFile || !this.citiesFile || !this.districtsFile) {
        alert(this.$t("elections.uploadAllFiles"));
        return;
      }

      this.loading = true;

      let formData = new FormData();
      formData.append('title', this.title);
      formData.append('date_from', this.dateFrom);
      formData.append('date_to', this.dateTo);
      formData.append('date_opening', this.dateOpening);
      formData.append('date_closing', this.dateClosing);
      formData.append('regions', this.regionsFile);
      formData.append('cities', this.citiesFile);
      formData.append('districts', this.districtsFile);
      this.$store.getters.api.post('/elections', formData).then((response) => {
        this.loading = false;
        this.$router.push({ name: 'dashboard' });
      }).catch((error) => {
        this.loading = false;
        if (error.response && error.response.data && error.response.data.error_code) {
          alert(this.$t("error." + error.response.data.error_code));
        }
        else {
          alert(this.$t("error.undefined" + ": " + error));
        }
      });
    },
  }
}
</script>

<style lang="scss"></style>