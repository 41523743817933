<template>
  <div>
    <div class="row p-4">
      <div class="col-12">
        <div class="row">
            <h1 class="mb-4">{{ $t("dashboard.title") }}</h1>
            <div class="col-12 mb-4">
              <router-link to="/dashboard">{{ $t("dashboard.elections") }}</router-link>
            </div>
            <h2 class="mb-4" v-if="model">{{ this.$t(`dashboard.title${model}`) }}</h2>
        </div>
        <ElectionsList :items="items" :model="model" :loading="loading" @loadData="loadData" />
      </div>
    </div>
  </div>
</template>

<script>
import ElectionsList from "@/components/ElectionsList.vue"

export default {
  components: {
    ElectionsList
  },
  data() {
    return {
      items: [],
      model: "",
      loading: false
    }
  },
  created() {
    this.loadData();
  },
    watch: {
      "$route.params": {
        handler: function (val) {
          this.loadData();
        },
        deep: true,
        immediate: true
      }
    },
  methods: {
    loadData() {
      this.loading = true;
      this.$store.getters.api.get('/list').then((response) => {
        this.items = response.data.items;
        this.model = response.data.model;
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        if (error.response && error.response.data && error.response.data.error_code) {
          alert(this.$t("error." + error.response.data.error_code));
        }
        else {
          alert(this.$t("error.undefined" + ": " + error));
        }
      });
    },
  }
}
</script>

<style lang="scss">
</style>