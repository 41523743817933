<template>
    <div v-if="loading" class="overlay">
      <div class="spinner-container">
        <div class="spinner-border spinner-color" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </template>

  <script>
  export default {
    props: {
      loading: {
        type: Boolean,
        required: true
      }
    }
  }
  </script>

  <style scoped lang="scss">
  @import "@/scss/main.scss";
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner-border {
    width: 3rem;
    height: 3rem;
  }

  .spinner-color {
    color: $primary
  }
  </style>
