<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-player-skip-forward" width="20" height="20" viewBox="3 3 20 20" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
    <path d="M4 5v14l12 -7z" />
    <line x1="20" y1="5" x2="20" y2="19" />
  </svg>
</template>
  
<script>
  export default {}
</script>
  
<style>
 
</style>