<template>
    <svg width="37" height="28" viewBox="0 0 37 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_229_34)">
            <path
                d="M37 7.40072V9.0831C37 9.35688 36.7778 9.57766 36.5022 9.57766H0.497778C0.222222 9.57766 0 9.35688 0 9.0831V7.3963C0 5.4843 1.56 3.93439 3.48889 3.93439H33.5156C35.44 3.93439 37.0044 5.4843 37.0044 7.40072H37Z"
                fill="#1E2D4B" />
            <path
                d="M9.32446 1.18783C9.32446 0.531807 8.78919 0 8.12891 0C7.46862 0 6.93335 0.531807 6.93335 1.18783V4.42012C6.93335 5.07614 7.46862 5.60795 8.12891 5.60795C8.78919 5.60795 9.32446 5.07614 9.32446 4.42012V1.18783Z"
                fill="#1E2D4B" />
            <path
                d="M30.1201 1.18783C30.1201 0.531807 29.5848 0 28.9246 0C28.2643 0 27.729 0.531807 27.729 1.18783V4.42012C27.729 5.07614 28.2643 5.60795 28.9246 5.60795C29.5848 5.60795 30.1201 5.07614 30.1201 4.42012V1.18783Z"
                fill="#FF862B" />
            <path
                d="M36.5022 10.7655H0.493333C0.222222 10.7655 0 10.9863 0 11.2556V24.5381C0 26.4501 1.56 28 3.48889 28H33.52C35.4444 28 37.0044 26.4501 37.0044 24.5381V11.2556C37.0044 10.9863 36.7822 10.7655 36.5111 10.7655H36.5022Z"
                fill="#1E2D4B" />
            <path
                d="M9.82672 13.6887H5.04006C4.66696 13.6887 4.3645 13.9892 4.3645 14.3599V18.206C4.3645 18.5766 4.66696 18.8771 5.04006 18.8771H9.82672C10.1998 18.8771 10.5023 18.5766 10.5023 18.206V14.3599C10.5023 13.9892 10.1998 13.6887 9.82672 13.6887Z"
                fill="#FF862B" />
            <path
                d="M17.2044 13.6887H12.4177C12.0446 13.6887 11.7422 13.9892 11.7422 14.3599V18.206C11.7422 18.5766 12.0446 18.8771 12.4177 18.8771H17.2044C17.5775 18.8771 17.88 18.5766 17.88 18.206V14.3599C17.88 13.9892 17.5775 13.6887 17.2044 13.6887Z"
                fill="white" />
            <path
                d="M24.5823 13.6887H19.7957C19.4226 13.6887 19.1201 13.9892 19.1201 14.3599V18.206C19.1201 18.5766 19.4226 18.8771 19.7957 18.8771H24.5823C24.9554 18.8771 25.2579 18.5766 25.2579 18.206V14.3599C25.2579 13.9892 24.9554 13.6887 24.5823 13.6887Z"
                fill="#FF862B" />
        <path
            d="M31.96 13.6887H27.1734C26.8003 13.6887 26.4978 13.9892 26.4978 14.3599V18.206C26.4978 18.5766 26.8003 18.8771 27.1734 18.8771H31.96C32.3331 18.8771 32.6356 18.5766 32.6356 18.206V14.3599C32.6356 13.9892 32.3331 13.6887 31.96 13.6887Z"
            fill="#FF862B" />
        <path
            d="M9.82672 19.8928H5.04006C4.66696 19.8928 4.3645 20.1933 4.3645 20.5639V24.41C4.3645 24.7807 4.66696 25.0812 5.04006 25.0812H9.82672C10.1998 25.0812 10.5023 24.7807 10.5023 24.41V20.5639C10.5023 20.1933 10.1998 19.8928 9.82672 19.8928Z"
            fill="#FF862B" />
        <path
            d="M17.2044 19.8928H12.4177C12.0446 19.8928 11.7422 20.1933 11.7422 20.5639V24.41C11.7422 24.7807 12.0446 25.0812 12.4177 25.0812H17.2044C17.5775 25.0812 17.88 24.7807 17.88 24.41V20.5639C17.88 20.1933 17.5775 19.8928 17.2044 19.8928Z"
            fill="#FF862B" />
        <path
            d="M24.5823 19.8928H19.7957C19.4226 19.8928 19.1201 20.1933 19.1201 20.5639V24.41C19.1201 24.7807 19.4226 25.0812 19.7957 25.0812H24.5823C24.9554 25.0812 25.2579 24.7807 25.2579 24.41V20.5639C25.2579 20.1933 24.9554 19.8928 24.5823 19.8928Z"
            fill="white" />
        <path
            d="M31.96 19.8928H27.1734C26.8003 19.8928 26.4978 20.1933 26.4978 20.5639V24.41C26.4978 24.7807 26.8003 25.0812 27.1734 25.0812H31.96C32.3331 25.0812 32.6356 24.7807 32.6356 24.41V20.5639C32.6356 20.1933 32.3331 19.8928 31.96 19.8928Z"
            fill="#FF862B" />
    </g>
    <defs>
        <clipPath id="clip0_229_34">
            <rect width="37" height="28" fill="white" />
        </clipPath>
    </defs>
</svg>
</template>