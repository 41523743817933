import { createRouter, createWebHistory } from 'vue-router';
import VueCookies from 'vue-cookies'

import store from "@/store.js"

import Login from '@/views/Login.vue';
import Dashboard from '@/views/Dashboard.vue';
import Profile from '@/views/Profile.vue';
import ThankYou from '@/views/ThankYou.vue';
import AddElections from '@/views/AddElections.vue';
import Election from '@/views/Election.vue';
import City from '@/views/City.vue';
import District from '@/views/District.vue';
import Okrsek from '@/views/Okrsek.vue';
import Orp from '@/views/Orp.vue';
import Region from '@/views/Region.vue';
import MemberList from '@/views/MemberList.vue';
import CloseElections from '@/views/CloseElections.vue';

const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            title: "titles.login",
            navbar: false,
        }
    },
    {
        path: '/logout/',
        name: 'logout',
        async beforeEnter(to, from, next) {
            // after logout redirect to login
            await store.getters.api.get("/auth/logout");
            store.commit("saveUserProfile", {});
            next({ name: "login" });
        }
    },
    {
        path: '/oauth/callback',
        name: 'callback',
        async beforeEnter(to, from, next) {
            const code = to.query.code;

            if (code) {
                await store.getters.api.post("/auth/login", { code: code }).then((response) => {
                    store.commit("saveUserProfile", response.data.user);

                    // try to get path from cookies
                    var path = VueCookies.get("pathRedirect");
                    if (path) {
                        VueCookies.remove("pathRedirect");
                        return next({ path: path});
                    }
                    next({ name: "dashboard" });
                }).catch((error) => {
                    if (error.response && error.response.data && error.response.data.error_code) {
                      alert(this.$t("error." + error.response.data.error_code ));
                    }
                    else {
                      alert(this.$t("error.undefined" + ": " + error));
                    }
                });
            }

            next({ name: "login" });
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            title: "titles.dashboard",
            navbar: true,
        }
    },
    {
        path: '/closing',
        name: 'closing',
        component: CloseElections,
        meta: {
            title: "titles.closing",
            navbar: true,
        }
    },
    {
        path: '/election/:uuid',
        name: 'election',
        component: Election,
        meta: {
            title: "titles.dashboard",
            navbar: true,
        }
    },
    {
        path: '/region/:uuid',
        name: 'region',
        component: Region,
        meta: {
            title: "titles.dashboard",
            navbar: true,
        }
    },
    {
        path: '/district/:uuid',
        name: 'district',
        component: District,
        meta: {
            title: "titles.dashboard",
            navbar: true,
        }
    },
    {
        path: '/orp/:uuid',
        name: 'orp',
        component: Orp,
        meta: {
            title: "titles.dashboard",
            navbar: true,
        }
    },
    {
        path: '/city/:uuid',
        name: 'city',
        component: City,
        meta: {
            title: "titles.dashboard",
            navbar: true,
        }
    },
    {
        path: '/okrsek/:uuid',
        name: 'okrsek',
        component: Okrsek,
        meta: {
            title: "titles.dashboard",
            navbar: true,
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            title: "titles.profile",
            navbar: true,
        }
    },
    {
        path: '/thankyou',
        name: 'thank-you',
        component: ThankYou,
        meta: {
            title: "titles.thankyou",
            navbar: false,
        }
    },
    {
        path: '/add-elections',
        name: 'add-elections',
        component: AddElections,
        meta: {
            title: "titles.addElections",
            navbar: true,
        },
        beforeEnter(to, from, next) {
            if (!store.getters.hasPerm("can_create_elections")) {
                return next({ name: 'dashboard'});
            }
            return next()
        }
    },
    {
        path: '/election/:uuid/members',
        name: 'election-members',
        component: MemberList,
        meta: {
            title: "titles.electionMembers",
            navbar: true,
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

router.beforeEach((to, from, next) => {
    if (store.getters.isLoggedIn || ['login', 'logout', 'sign_up', 'thank-you', 'callback'].includes(to.name)) {
        next();
    } else if (!store.getters.isLoggedIn) {
        store.getters.api.post("/auth/re-login").then((response) => {
            store.commit("saveUserProfile", response.data.user);
            next();
        }).catch((error) => {
            next({ name: 'login', query: { path: to.path } });
        });
    } else {
        next({ name: 'login', query: { path: to.path } });
    }
})

export default router;