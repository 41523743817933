<template>
  <template v-if="!loading">
    <template v-if="items && items.length > 0">

      <div class="row table-container mx-1">
        <div class="col-4 header pb-3">
          {{ $t("dashboard.tableName") }}
        </div>
        <div class="col-3 header pb-3">
          {{ $t("dashboard.tableAvailable") }}
        </div>
        <div class="col-3 header pb-3">
          {{ $t("dashboard.tableOccupied") }}
        </div>
        <div class="col-2 header pb-3">
          {{ $store.getters.hasPerm('can_manage_elections') ? $t("dashboard.tableDatabox") : "" }}
        </div>
      </div>

      <div class="pb-3 table-container">
        <template v-for="(item, index) in items" :key="index">
          <router-link :to="{name: children, params: {uuid: item.slug}}" class="item row d-flex align-items-stretch mx-1">
            <div class="py-2 d-flex align-items-center cursor_pointer col-4">
              <a href="#">{{ item.okrsek_id ? `Okrsek ${item.okrsek_id}` : item.name }}</a>
            </div>
            <div class="py-2 d-flex align-items-center cursor_pointer col-3">
              {{ available(item) }}
            </div>
            <div class="py-2 d-flex align-items-center cursor_pointer col-3">
              {{ occupied(item) }}
            </div>
            <div v-if="$store.getters.hasPerm('can_manage_elections')" class="py-2 d-flex align-items-center cursor_pointer col-2"
            :class="(item.databox_count && item.databox_total && (item.databox_count !== item.databox_total)) ? 'red' : ''">
              {{ databox(item) }}
            </div>
          </router-link>
        </template>
      </div>

    </template>
    <div v-else class="col-12 mb-4">{{ $t("dashboard.noItems") }}</div>
  </template>

  <template v-else>
      <div class="col-12">
        <div class="d-flex justify-content-center my-5">
          <div class="spinner-border spinner-color" style="width: 3rem; height: 3rem;" role="status">
            <span class="visually-hidden">{{ $t("utils.loading") }}</span>
          </div>
        </div>
      </div>
    </template>
</template>

<script>
export default {
  emits: ['loadData'],
  props: {
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    model: {
      type: String,
      required: true
    }
  },
  computed: {
    children: function () {
      var name = this.model.toLowerCase();
      if (name == 'elections') {
        return 'election';
      }
      if (name == 'extendedcity') {
        return 'orp';
      }
      if (name == 'citypart') {
        return 'city';
      }
      return name;
    }
  },
  methods: {
    available(item) {
      if (item.okrsek_id) {
        return item.member === null ? "1" : "0";
      }
      return `${item.total_count - item.member_count}`
    },
    occupied(item) {
      if (item.okrsek_id) {
        return item.member === null ? "0 / 1 (0 %)" : "1 / 1 (100 %)";
      }
      if (!item.total_count) {
        return "0 / 0 (100 %)";
      }
      return `${item.member_count} / ${item.total_count} (${Math.round(item.member_count / item.total_count * 100)} %)`
    },
    databox(item) {
      if (item.databox_id) {
        return item.databox_id;
      }
      return item.databox_count && item.databox_total ? `${item.databox_count} / ${item.databox_total}` : "-";
    },
    loadData() {
      this.$emit('loadData')
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";

.table-container {
    min-width: 750px;
}

.header {
    font-weight: bold;
    background-color: $white;
    border-bottom: 2px solid black;
}

.item {
    div &:nth-of-type(odd) {
        background-color: $background-color;
        border-bottom: 1px solid grey;

        &:hover {
            background-color: darken($background-color, 5%);
        }
    }

    &:nth-of-type(even) {
        background-color: $white;
        border-bottom: 1px solid grey;

        &:hover {
            background-color: darken($background-color, 5%);
        }
    }
}

a {
  color: #212529 !important;
  text-decoration: none !important;
}

.green {
  color: rgb(0, 138, 0) !important;
}

.red {
  color: rgb(209, 0, 0) !important;
}

.spinner-color {
    color: $primary
}

.cursor_pointer {
    cursor: pointer;
}
</style>