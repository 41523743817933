<template>
  <div>
    <div class="row p-4">
      <div class="col-12" v-if="$store.getters.hasPerm('can_manage_elections')">
        <div class="row">
          <h1 class="mb-4">{{ $t("members.title") }}</h1>
          <h2 class="mb-4" v-if="election">{{ election }}</h2>
          <h2 class="mb-4" v-else>...</h2>
          <h6>{{ $t("members.id") }}</h6>
          <input type="number" class="form-control mb-3" v-model="registerId">
          <h6>{{ $t("members.name") }}</h6>
          <input type="text" class="form-control mb-3" v-model="name">
          <h6>{{ $t("members.address") }}</h6>
          <input type="text" class="form-control mb-3" v-model="address">
          <h6>{{ $t("members.dateFrom") }}</h6>
          <input type="date" class="form-control mb-3" v-model="dateFrom">
          <h6>{{ $t("members.dateTo") }}</h6>
          <input type="date" class="form-control mb-4" v-model="dateTo">
          <h6>
            {{ $t("members.isRegistered") }}&nbsp;
            <input type="checkbox" class="form-check-input" v-model="isRegistered">
          </h6>
          <h6>
            {{ $t("members.isNotRegistered") }}&nbsp;
            <input type="checkbox" class="form-check-input" v-model="isNotRegistered">
          </h6>
          <h6>
            {{ $t("members.isContacted") }}&nbsp;
            <input type="checkbox" class="form-check-input" v-model="isContacted">
          </h6>
          <h6>
            {{ $t("members.isNotContacted") }}&nbsp;
            <input type="checkbox" class="form-check-input" v-model="isNotContacted">
          </h6>
          <button class="btn btn-primary text-white my-4" @click="loadData()">
            {{ $t("members.search") }}
          </button>
        </div>

        <Popup
          :header="$t('members.changeOkrsek')"
          :editable="false"
          :button="$t('members.changeOkrsekButton')"
          id="changeOkrsek"
          @save-data="updateOkrsek"
        >
          <template #body>
            <input v-model="okrsekId" class="form-control ">
          </template>
        </Popup>

        <template v-if="!loading">
          <template v-if="items && items.length > 0">

            <div class="row table-container mx-1">
              <div class="col-1 header pb-3">
                ID
              </div>
              <div class="col-2 header pb-3">
                {{ $t("members.name") }}
              </div>
              <div class="col-2 header pb-3">
                {{ $t("members.address") }}
              </div>
              <div class="col-2 header pb-3">
                {{ $t("members.email") }}
              </div>
              <div class="col-2 header pb-3">
                {{ $t("members.phone") }}
              </div>
              <div class="col-1 header pb-3">
                {{ $t("members.lastLogin") }}
              </div>
              <div class="col-1 header pb-3">
                {{ $t("members.okrsek") }}
              </div>
              <div class="col-1 header pb-3">
                {{ $t("members.action") }}
              </div>
            </div>

            <div class="pb-3 table-container">
              <template v-for="(item, index) in items" :key="index">
                <div class="item row d-flex align-items-stretch mx-1">
                  <div class="py-2 d-flex align-items-center col-1">
                    <a :href="registerUrl + '/member/' + item.register_id" target="_blank">
                      {{ item.register_id }}
                    </a>
                  </div>
                  <div class="py-2 d-flex align-items-center col-2">
                    {{ item?.name_prefix || "" }} {{ item.first_name }} {{ item.last_name }}{{ item.name_suffix ? `, ${item.name_suffix}` : "" }}
                  </div>
                  <div class="py-2 d-flex align-items-center col-2">
                    {{ item.domestic_address }}
                  </div>
                  <div class="py-2 d-flex align-items-center col-2">
                    {{ item.email }}
                  </div>
                  <div class="py-2 d-flex align-items-center col-2">
                    {{ item.phone }}
                  </div>
                  <div class="py-2 d-flex align-items-center col-1">
                    {{ item.last_login }}
                  </div>
                  <div class="py-2 d-flex align-items-center col-1">
                    <router-link v-if="item.okrsek_slug" :class="item.okrsek ? 'green' : 'red'" :to="{name: 'okrsek', params: {uuid: item.okrsek_slug}}">
                      {{ item.okrsek ? item.okrsek : "---" }}
                    </router-link>
                    <span v-else :class="item.okrsek ? 'green' : 'red'">{{ item.okrsek ? item.okrsek : "---" }}</span>
                  </div>
                  <div class="col-1 d-flex align-items-center flex-column justify-content-center">
                    <button v-if="!item.okrsek_slug" class="btn btn-primary text-white my-2"
                    data-bs-toggle="modal"
                    data-bs-target="#changeOkrsek"
                    @click="() => { okrsekId = null; okrsekMemberId=item.register_id }">
                      {{ $t("members.add") }}
                    </button>
                    <button v-if="item.okrsek_slug" class="btn btn-primary text-white mt-2"
                      data-bs-toggle="modal"
                      data-bs-target="#changeOkrsek"
                      @click="() => { okrsekId = item.okrsek_slug; okrsekMemberId=item.register_id }">
                      {{ $t("members.change") }}
                    </button>
                    <button v-if="!item.contacted" class="btn btn-primary text-white my-2"
                    @click="contact(item.register_id, true)">
                      {{ $t("members.contacted") }}
                    </button>
                    <button v-else class="btn btn-primary text-white my-2"
                    @click="contact(item.register_id, false)">
                      {{ $t("members.decontacted") }}
                    </button>
                  </div>
                </div>
              </template>
            </div>

          </template>
          <div v-else class="col-12 mb-4">{{ $t("dashboard.noItems") }}</div>
        </template>

        <template v-else>
          <div class="col-12">
            <div class="d-flex justify-content-center my-5">
              <div class="spinner-border spinner-color" style="width: 3rem; height: 3rem;" role="status">
                <span class="visually-hidden">{{ $t("utils.loading") }}</span>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Popup from '@/components/Popup.vue';
import { CONFIG } from "@/config.js";

export default {
  components: {
    Popup
  },
  data() {
    return {
      items: [],
      election: "",
      loading: false,
      registerId: null,
      name: "",
      address: "",
      dateFrom: "",
      dateTo: "",
      isRegistered: false,
      isNotRegistered: false,
      isNotContacted: false,
      isContacted: false,
      registerUrl: CONFIG.registerVueAppUrl,
      newPersonId: null,
      okrsekId: null,
      okrsekMemberId: null
    }
  },
  created() {
    this.loadData();
  },
    watch: {
      "$route.params": {
        handler: function (val) {
          this.loadData();
        },
        deep: true,
        immediate: true
      }
    },
  methods: {
    loadData() {
      this.loading = true;
      const params = {
        register_id: this.registerId,
        name: this.name,
        address: this.address,
        date_from: this.dateFrom,
        date_to: this.dateTo,
        is_registered: this.isRegistered,
        is_not_registered: this.isNotRegistered,
        is_contacted: this.isContacted,
        is_not_contacted: this.isNotContacted
      }
      this.$store.getters.api.get('/members/' + this.$route.params.uuid, {params: params}).then((response) => {
        this.items = response.data.items;
        this.election = response.data.election;
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        if (error.response && error.response.data && error.response.data.error_code) {
          alert(this.$t("error." + error.response.data.error_code));
        }
        else {
          alert(this.$t("error.undefined" + ": " + error));
        }
      });
    },
    contact: function (user_id, contacted) {
      var data = {
        person: user_id,
        contacted: contacted
      }

      this.$store.getters.api.post('election/' + this.$route.params.uuid + '/contacted/person', data).then(function () {
        this.loadData()
      }.bind(this)).catch((error) => {
        if (error.response && error.response.data && error.response.data.error_code) {
          alert(this.$t("error." + error.response.data.error_code));
        }
        else {
          alert(this.$t("error.undefined" + ": " + error));
        }
      });
    },
    updateOkrsek: function () {
      var data = {
        okrsek: this.okrsekId,
        person: this.okrsekMemberId
      }

      this.$store.getters.api.post('election/' + this.$route.params.uuid + '/okrsek', data).then(function () {
        this.loadData()
      }.bind(this)).catch((error) => {
        if (error.response && error.response.data && error.response.data.error_code) {
          alert(this.$t("error." + error.response.data.error_code));
        }
        else {
          alert(this.$t("error.undefined" + ": " + error));
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";

.table-container {
    min-width: 750px;
}

.header {
    font-weight: bold;
    background-color: $white;
    border-bottom: 2px solid black;
}

.item {
    div &:nth-of-type(odd) {
        background-color: $background-color;
        border-bottom: 1px solid grey;

        &:hover {
            background-color: darken($background-color, 5%);
        }
    }

    &:nth-of-type(even) {
        background-color: $white;
        border-bottom: 1px solid grey;

        &:hover {
            background-color: darken($background-color, 5%);
        }
    }
}

a {
  color: #212529 !important;
  text-decoration: none !important;
}

.green {
  color: rgb(0, 138, 0) !important;
}

.red {
  color: rgb(209, 0, 0) !important;
}

.spinner-color {
    color: $primary
}
</style>