<template>
  <div class="container-fluid">
    <div v-if="showNavbar">
      <NavbarHorizontal/>
      <router-view class="navbar_height_margin"/>
    </div>
    <!-- <div v-if="showNavbar">
      <NavbarVertical/>
      <div>
        <router-view/>
      </div>
    </div> -->
    <div v-else>
      <router-view/>
    </div>
  </div>
</template>

<script>
import NavbarHorizontal from "@/components/NavbarHorizontal.vue"
import NavbarVertical from "@/components/NavbarVertical.vue"

export default {
  components: {
    NavbarHorizontal,
    NavbarVertical
  },
  computed: {
    pageTitle: function () {
      const title = this.$route.meta.title;
      let title_tranlation = null;

      if (title != null) {
        title_tranlation = this.$t(title);
        document.title = "Volební okrsky - " + this.$t(title);
      }

      return title_tranlation;
    },
    showNavbar: function () {
      if (this.$route.meta.navbar != null) {
        return this.$route.meta.navbar;
      }
      return true;
    }
  },
  watch: {
    // lookout for changes
    pageTitle: function () {}
  }
}
</script>

<style lang="scss">
@import "@/scss/main.scss";
</style>