<template>
    <div>
      <div class="row p-4">
        <div class="col-12">
          <div class="row">
            <h1 class="mb-4">{{ $t("dashboard.title") }}</h1>
            <div class="col-12 mb-4">
              <router-link to="/dashboard">{{ $t("dashboard.elections") }}</router-link>
                <template v-if="names && names.election">
                  <span> > </span>
                  <router-link :to="{name: 'election', params: {uuid: names.election.slug}}">{{ names.election.name }}</router-link>
                </template>
                <template v-if="names && names.region">
                  <span> > </span>
                  <router-link :to="{name: 'region', params: {uuid: names.region.slug}}">{{ names.region.name }}</router-link>
                </template>
                <template v-if="names && names.district">
                  <span> > </span>
                  <router-link :to="{name: 'district', params: {uuid: names.district.slug}}">{{ $t('dashboard.prefixDistrict') }} {{ names.district.name }}</router-link>
                </template>
                <template v-if="names && names.extendedCity">
                  <span> > </span>
                  <router-link :to="{name: 'orp', params: {uuid: names.extendedCity.slug}}">{{ $t('dashboard.prefixExtendedCity') }} {{ names.extendedCity.name }}</router-link>
                </template>
                <template v-if="names && names.cityPart">
                  <span> > </span>
                  <router-link :to="{name: 'city', params: {uuid: names.cityPart.slug}}">{{ $t('dashboard.prefixCityPart') }} {{ names.cityPart.name }}</router-link>
                </template>
                <template v-if="names && names.current">
                  <span> > </span>
                  <router-link :to="{name: 'okrsek', params: {uuid: names.current.slug}}">{{ $t('dashboard.prefixOkrsek') }} {{ names.current.name }}</router-link>
                </template>
            </div>
            <h2 class="mb-4" v-if="names?.current?.name">{{ this.$t(`dashboard.titleActualOkrsek`) }} {{ names.current.name }}</h2>
          </div>
          <Members :member_ids="this.items[0]" @reload="loadData()"/>
        </div>
      </div>
    </div>
  </template>

  <script>
  import Members from "@/components/Members.vue"

  export default {
    components: {
      Members
    },
    data() {
      return {
        items: [],
        model: "",
        loading: false,
        names: null
      }
    },
    created() {
      this.loadData();
    },
    watch: {
      "$route.params": {
        handler: function (val) {
          this.loadData();
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      loadData() {
        this.loading = true;
        this.$store.getters.api.get('/list', {params: {model: 'Okrsek', parent: this.$route.params.uuid}}).then((response) => {
          this.items = response.data.items;
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
          if (error.response && error.response.data && error.response.data.error_code) {
            alert(this.$t("error." + error.response.data.error_code));
          }
          else {
            alert(this.$t("error.undefined" + ": " + error));
          }
        });

        this.$store.getters.api.get('/parents', {params: {model: 'Okrsek', parent: this.$route.params.uuid}}).then((response) => {
          this.names = response.data.data;
        }).catch((error) => {
          this.loading = false;
          if (error.response && error.response.data && error.response.data.error_code) {
            alert(this.$t("error." + error.response.data.error_code));
          }
          else {
            alert(this.$t("error.undefined" + ": " + error));
          }
        });
      },
    }
  }
  </script>

  <style lang="scss">
  </style>