<template>
  <p class="px-2">
    <slot name="header">{{ header }}:&nbsp;</slot>
    <template v-if="editable">
      <slot name="editor">
        <input class="form-control mt-1" :type="inputType" v-model="model" />
      </slot>
    </template>
    <template v-else>
      <slot name="body">{{ body ? body : "-" }}</slot>
    </template>
  </p>
</template>

<script>
export default {
  props: {
    header: {
      type: String,
      required: false
    },
    body: {
      required: false
    },
    inputType: {
      type: String,
      required: false,
      default: "text"
    },
    editable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    model: {
      get() {
        return this.body
      },
      set(newValue) {
        this.$emit('body', newValue)
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
