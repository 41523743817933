<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-player-track-next" width="24" height="20" viewBox="0 3 24 20" stroke-width="1.5" stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
    <path d="M3 5v14l8 -7z" />
    <path d="M14 5v14l8 -7z" />
  </svg>
</template>
  
<script>
  export default {}
</script>
  
<style>
 
</style>