<template>
  <template v-if="!loading">
    <template v-if="items && items.length > 0">

      <div class="row table-container mx-1">
        <div class="col-4 header pb-3">
          {{ $t("closing.electionName") }}
        </div>
        <div class="col-2 header pb-3">
          {{ $t("closing.statementCount") }}
        </div>
        <div class="col-2 header pb-3">
          {{ $t("closing.memberCount") }}
        </div>
        <div class="col-2 header pb-3">
          {{ $t("closing.databoxes") }}
        </div>
        <div class="col-2 header pb-3">
          {{ $store.getters.hasPerm('can_manage_elections') ? $t("closing.action") : "" }}
        </div>
      </div>

      <div class="pb-3 table-container">
        <template v-for="(item, index) in items" :key="index">
          <div class="item row d-flex align-items-stretch mx-1">
            <div class="py-2 d-flex align-items-center col-4">
              {{ item.name }}
            </div>
            <div class="py-2 d-flex align-items-center col-2">
              {{ `${item.statement_count} (${$t("closing.title" + item.statement_level)})` }}
            </div>
            <div class="py-2 d-flex align-items-center col-2">
              {{ occupied(item) }}
            </div>
            <div v-if="$store.getters.hasPerm('can_manage_elections')" class="py-2 d-flex align-items-center col-2"
            :class="(item.databox_count && item.databox_total && (item.databox_count !== item.databox_total)) ? 'red' : ''">
              {{ databox(item) }}
            </div>
            <div class="py-2 d-flex align-items-center col-2 flex-column">
              <button class="btn btn-primary text-white" @click="updatePeople(item.slug)">
                {{ $t("closing.updatePeople") }}
              </button>
              <button class="btn btn-primary text-white mt-2" @click="loadData(item.slug)">
                {{ $t("closing.closeElections") }}
              </button>
              <button class="btn btn-primary text-white mt-2"
                @click="() => {selectedElection = item.slug}"
                data-bs-toggle="modal" data-bs-target="#acceptMessages">
                {{ $t("closing.acceptMessages") }}
              </button>
              <button class="btn btn-primary text-white mt-2"
                @click="() => {selectedElection = item.slug}"
                data-bs-toggle="modal" data-bs-target="#sendMessages">
                {{ $t("closing.sendMessages") }}
              </button>
            </div>
          </div>
        </template>
      </div>

    </template>
    <div v-else class="col-12 mb-4">{{ $t("dashboard.noItems") }}</div>
  </template>

  <template v-else>
      <div class="col-12">
        <div class="d-flex justify-content-center my-5">
          <div class="spinner-border spinner-color" style="width: 3rem; height: 3rem;" role="status">
            <span class="visually-hidden">{{ $t("utils.loading") }}</span>
          </div>
        </div>
      </div>
    </template>

    <Popup
      :header="$t('closing.acceptMessagesTitle')"
      :editable="false"
      :button="$t('closing.acceptMessages')"
      id="acceptMessages"
      @save-data="acceptMessages"
    >
      <template #body>
        <p>{{  $t("closing.acceptMessagesDescription") }}</p>
      </template>
    </Popup>

    <Popup
      :header="$t('closing.sendMessagesTitle')"
      :editable="false"
      :button="$t('closing.sendMessages')"
      id="sendMessages"
      @save-data="sendMessages"
    >
      <template #body>
        <p>{{  $t("closing.sendMessagesDescription") }}</p>
      </template>
    </Popup>
</template>

<script>
import Popup from "@/components/Popup.vue";

export default {
  components: {
    Popup,
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      selectedElection: null
    }
  },
  methods: {
    occupied(item) {
      if (item.okrsek_id) {
        return item.member === null ? "0 / 1 (0 %)" : "1 / 1 (100 %)";
      }
      if (!item.total_count) {
        return "0 / 0 (100 %)";
      }
      return `${item.member_count} / ${item.total_count} (${Math.round(item.member_count / item.total_count * 100)} %)`
    },
    databox(item) {
      if (item.databox_id) {
        return item.databox_id;
      }
      return item.databox_count && item.databox_total ? `${item.databox_count} / ${item.databox_total}` : "-";
    },
    loadData(slug) {
      this.$store.getters.api.get(`/election/${slug}/member_list`).then((_response) => {}).catch((error) => {
        if (error.response && error.response.data && error.response.data.error_code) {
          alert(this.$t("error." + error.response.data.error_code));
        }
        else {
          alert(this.$t("error.undefined" + ": " + error));
        }
      });
    },
    updatePeople(slug) {
      this.$store.getters.api.get(`/election/${slug}/update_people`).then((_response) => {}).catch((error) => {
        if (error.response && error.response.data && error.response.data.error_code) {
          alert(this.$t("error." + error.response.data.error_code));
        }
        else {
          alert(this.$t("error.undefined" + ": " + error));
        }
      });
    },
    sendMessages() {
      this.$store.getters.api.get(`/election/${this.selectedElection}/messages/send`).then((_response) => {}).catch((error) => {
        if (error.response && error.response.data && error.response.data.error_code) {
          this.selectedElection = null;
          alert(this.$t("error." + error.response.data.error_code));
        }
        else {
          alert(this.$t("error.undefined" + ": " + error));
        }
      });
    },
    acceptMessages() {
      this.$store.getters.api.get(`/election/${this.selectedElection}/messages/accepted`).then((_response) => {}).catch((error) => {
        if (error.response && error.response.data && error.response.data.error_code) {
          this.selectedElection = null;
          alert(this.$t("error." + error.response.data.error_code));
        }
        else {
          alert(this.$t("error.undefined" + ": " + error));
        }
      });
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/_variables.scss";

.table-container {
    min-width: 750px;
}

.header {
    font-weight: bold;
    background-color: $white;
    border-bottom: 2px solid black;
}

.item {
    div &:nth-of-type(odd) {
        background-color: $background-color;
        border-bottom: 1px solid grey;

        &:hover {
            background-color: darken($background-color, 5%);
        }
    }

    &:nth-of-type(even) {
        background-color: $white;
        border-bottom: 1px solid grey;

        &:hover {
            background-color: darken($background-color, 5%);
        }
    }
}

.green {
  color: rgb(0, 138, 0) !important;
}

.red {
  color: rgb(209, 0, 0) !important;
}

.spinner-color {
    color: $primary
}

.cursor_pointer {
    cursor: pointer;
}
</style>