<template>
    <div class="text-center mt-5">
        <h1>{{ $t("thankyou.formCompleted") }}</h1>
        <p>{{ $t("thankyou.thankYou") }}</p>
        <button class="btn btn-primary mt-4 text-white" @click="login">{{ $t("thankyou.button") }}</button>
    </div>
</template>

<script>
import { CONFIG } from "@/config.js"

export default {
    data() {
        return {
        }
    },
    methods: {
        login: function () {
            const params = {
                client_id: CONFIG.registerOauthClientId,
                response_type: "code",
                scope: "read",
                redirect_uri: CONFIG.vueAppUrl + "/oauth/callback"
            }
            const queryString = Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&');
            window.location.href = CONFIG.api.registerUrl + "/oauth/authorize/?" + queryString;
        }
    }
}
</script>

<style lang="scss">
</style>