<template>
  <div class="col-12 mb-4">
    <EditableRow :header="`${$t('elections.databox')} ${city.name}`" :editable="editable" :body="databox_id"
      @body="(newValue) => { databox_id = newValue }" />
    <button class="btn btn-primary text-white ms-2" @click="updateData">
      {{ editable ? $t('utils.saveChanges') : $t('utils.edit') }}
    </button>
  </div>
</template>

<script>
import EditableRow from "@/components/EditableRow.vue"

export default {
  components: {
    EditableRow
  },
  emits: ["reload"],
  props: {
    city: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      databox_id: "",
      editable: false
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    updateData() {
      if (!this.editable) {
        this.editable = true;
        return;
      }
      this.$store.getters.api.post(`/city/${this.city.slug}/databox`, { databox: this.databox_id }).then((response) => {
        this.databox_id = response.data.databox;
        this.editable = false;
      }).catch((error) => {
        this.editable = false;
        if (error.response && error.response.data && error.response.data.error_code) {
          alert(this.$t("error." + error.response.data.error_code));
        }
        else {
          alert(this.$t("error.undefined" + ": " + error));
        }
      });
    },
    loadData() {
      this.$store.getters.api.get(`/city/${this.city.slug}/databox`).then((response) => {
        this.databox_id = response.data.databox;
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.error_code) {
          alert(this.$t("error." + error.response.data.error_code));
        }
        else {
          alert(this.$t("error.undefined" + ": " + error));
        }
      });
    }
  }
}
</script>

<style scoped lang="scss"></style>
