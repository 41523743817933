<template>
<svg width="37" height="30" viewBox="0 0 37 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_190_184)">
    <path d="M35.0934 13.2997H1.90655C0.853592 13.2997 0 14.1599 0 15.221V15.2256C0 16.2867 0.853592 17.1468 1.90655 17.1468H35.0934C36.1464 17.1468 37 16.2867 37 15.2256V15.221C37 14.1599 36.1464 13.2997 35.0934 13.2997Z" fill="#1E2D4B"/>
    <path d="M35.0934 23.0036H1.90655C0.853592 23.0036 0 23.8637 0 24.9248V24.9295C0 25.9905 0.853592 26.8507 1.90655 26.8507H35.0934C36.1464 26.8507 37 25.9905 37 24.9295V24.9248C37 23.8637 36.1464 23.0036 35.0934 23.0036Z" fill="#1E2D4B"/>
    <path d="M9.14507 20.2962C11.9266 20.2962 14.1815 18.0239 14.1815 15.221C14.1815 12.418 11.9266 10.1458 9.14507 10.1458C6.36353 10.1458 4.10864 12.418 4.10864 15.221C4.10864 18.0239 6.36353 20.2962 9.14507 20.2962Z" fill="#1E2D4B"/>
    <path d="M27.4256 30.0047C30.2071 30.0047 32.462 27.7324 32.462 24.9294C32.462 22.1265 30.2071 19.8542 27.4256 19.8542C24.644 19.8542 22.3892 22.1265 22.3892 24.9294C22.3892 27.7324 24.644 30.0047 27.4256 30.0047Z" fill="#1E2D4B"/>
    <path d="M35.0934 3.14932H1.90655C0.853592 3.14932 0 4.00949 0 5.07055V5.0752C0 6.13627 0.853592 6.99643 1.90655 6.99643H35.0934C36.1464 6.99643 37 6.13627 37 5.0752V5.07055C37 4.00949 36.1464 3.14932 35.0934 3.14932Z" fill="#1E2D4B"/>
    <path d="M21.886 10.1504C24.6676 10.1504 26.9225 7.87816 26.9225 5.07521C26.9225 2.27225 24.6676 0 21.886 0C19.1045 0 16.8496 2.27225 16.8496 5.07521C16.8496 7.87816 19.1045 10.1504 21.886 10.1504Z" fill="#1E2D4B"/>
    <path d="M9.14508 17.1422C10.198 17.1422 11.0516 16.282 11.0516 15.221C11.0516 14.1599 10.198 13.2997 9.14508 13.2997C8.09212 13.2997 7.23853 14.1599 7.23853 15.221C7.23853 16.282 8.09212 17.1422 9.14508 17.1422Z" fill="#FF862B"/>
    <path d="M21.886 6.99643C22.939 6.99643 23.7926 6.13627 23.7926 5.0752C23.7926 4.01414 22.939 3.15398 21.886 3.15398C20.8331 3.15398 19.9795 4.01414 19.9795 5.0752C19.9795 6.13627 20.8331 6.99643 21.886 6.99643Z" fill="#FF862B"/>
    <path d="M27.4256 26.8507C28.4786 26.8507 29.3321 25.9905 29.3321 24.9294C29.3321 23.8684 28.4786 23.0082 27.4256 23.0082C26.3726 23.0082 25.519 23.8684 25.519 24.9294C25.519 25.9905 26.3726 26.8507 27.4256 26.8507Z" fill="#FF862B"/>
    </g>
    <defs>
    <clipPath id="clip0_190_184">
    <rect width="37" height="30" fill="white"/>
    </clipPath>
    </defs>
    </svg>
</template>