<template>
    <svg width="165" height="83" viewBox="0 0 165 83" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_234_311)">
<path d="M163.252 64.3598H155.255C153.916 64.3598 152.763 63.4662 152.416 62.1755C151.474 58.7503 148.548 50.8946 139.721 50.8946C130.894 50.8946 127.249 58.4152 125.712 61.8901C125.042 63.4042 123.542 64.3598 121.893 64.3598H98.3751C97.6312 64.3598 97.0237 63.7517 97.0237 63.007V59.4577H22.4396V61.3068C22.4396 62.1011 21.7949 62.7464 21.0015 62.7464H20.27C19.303 62.7464 18.522 63.5283 18.522 64.4963V70.6145C18.522 71.5825 19.303 72.3644 20.27 72.3644H163.252C164.219 72.3644 165 71.5825 165 70.6145V66.1096C165 65.1416 164.219 64.3598 163.252 64.3598Z" fill="#657595"/>
<path d="M162.322 55.3127V64.3598H155.106C153.805 64.3598 152.552 63.7641 151.784 62.7216C150.061 60.4257 146.155 56.4916 139.547 56.4916C132.939 56.4916 129.034 60.4133 127.311 62.7216C126.53 63.7641 125.29 64.3598 123.988 64.3598H99.5278V16.0093C99.5278 14.6441 100.631 13.5272 102.007 13.5272H116.922C118.36 13.5272 119.562 13.7878 121.868 15.1778C124.162 16.5553 124.435 17.151 125.873 18.6651C127.1 19.9433 139.089 35.7665 142.82 40.6809C143.527 41.6241 144.482 42.3439 145.573 42.7783C147.978 43.7463 152.466 45.583 155.255 47.0474C157.115 48.0154 158.788 49.2813 160.041 50.3361C160.859 51.0311 161.491 51.8998 161.875 52.8802C162.185 53.6497 162.347 54.4688 162.347 55.3127H162.322Z" fill="#FF862B"/>
<path d="M5.2318 0H91.8044C94.6931 0 97.0362 2.34554 97.0362 5.23714V59.4577H5.2318C2.34315 59.4577 0 57.1121 0 54.2205V5.23714C0 2.34554 2.34315 0 5.2318 0Z" fill="#FF862B"/>
<path d="M15.3481 10.2757C15.3481 8.74039 14.1047 7.49579 12.571 7.49579C11.0373 7.49579 9.79395 8.74039 9.79395 10.2757V48.1147C9.79395 49.65 11.0373 50.8946 12.571 50.8946C14.1047 50.8946 15.3481 49.65 15.3481 48.1147V10.2757Z" fill="#CE6B29"/>
<path d="M24.5349 10.2757C24.5349 8.74039 23.2915 7.49579 21.7578 7.49579C20.224 7.49579 18.9807 8.74039 18.9807 10.2757V48.1147C18.9807 49.65 20.224 50.8946 21.7578 50.8946C23.2915 50.8946 24.5349 49.65 24.5349 48.1147V10.2757Z" fill="#CE6B29"/>
<path d="M33.7219 10.2757C33.7219 8.74039 32.4785 7.49579 30.9448 7.49579C29.4111 7.49579 28.1677 8.74039 28.1677 10.2757V48.1147C28.1677 49.65 29.4111 50.8946 30.9448 50.8946C32.4785 50.8946 33.7219 49.65 33.7219 48.1147V10.2757Z" fill="#CE6B29"/>
<path d="M42.9081 10.2757C42.9081 8.74039 41.6648 7.49579 40.1311 7.49579C38.5973 7.49579 37.354 8.74039 37.354 10.2757V48.1147C37.354 49.65 38.5973 50.8946 40.1311 50.8946C41.6648 50.8946 42.9081 49.65 42.9081 48.1147V10.2757Z" fill="#CE6B29"/>
<path d="M52.0949 10.2757C52.0949 8.74039 50.8516 7.49579 49.3178 7.49579C47.7841 7.49579 46.5408 8.74039 46.5408 10.2757V48.1147C46.5408 49.65 47.7841 50.8946 49.3178 50.8946C50.8516 50.8946 52.0949 49.65 52.0949 48.1147V10.2757Z" fill="#CE6B29"/>
<path d="M61.2817 10.2757C61.2817 8.74039 60.0383 7.49579 58.5046 7.49579C56.9709 7.49579 55.7275 8.74039 55.7275 10.2757V48.1147C55.7275 49.65 56.9709 50.8946 58.5046 50.8946C60.0383 50.8946 61.2817 49.65 61.2817 48.1147V10.2757Z" fill="#CE6B29"/>
<path d="M70.468 10.2757C70.468 8.74039 69.2246 7.49579 67.6909 7.49579C66.1572 7.49579 64.9138 8.74039 64.9138 10.2757V48.1147C64.9138 49.65 66.1572 50.8946 67.6909 50.8946C69.2246 50.8946 70.468 49.65 70.468 48.1147V10.2757Z" fill="#CE6B29"/>
<path d="M79.6547 10.2757C79.6547 8.74039 78.4114 7.49579 76.8777 7.49579C75.3439 7.49579 74.1006 8.74039 74.1006 10.2757V48.1147C74.1006 49.65 75.3439 50.8946 76.8777 50.8946C78.4114 50.8946 79.6547 49.65 79.6547 48.1147V10.2757Z" fill="#CE6B29"/>
<path d="M88.8415 10.2757C88.8415 8.74039 87.5982 7.49579 86.0644 7.49579C84.5307 7.49579 83.2874 8.74039 83.2874 10.2757V48.1147C83.2874 49.65 84.5307 50.8946 86.0644 50.8946C87.5982 50.8946 88.8415 49.65 88.8415 48.1147V10.2757Z" fill="#CE6B29"/>
<path d="M108.615 19.4221C108.615 17.8868 107.372 16.6422 105.838 16.6422C104.305 16.6422 103.061 17.8868 103.061 19.4221V56.6777C103.061 58.213 104.305 59.4576 105.838 59.4576C107.372 59.4576 108.615 58.213 108.615 56.6777V19.4221Z" fill="#CE6B29"/>
<path d="M139.721 83C145.808 83 150.743 78.0605 150.743 71.9673C150.743 65.874 145.808 60.9345 139.721 60.9345C133.634 60.9345 128.7 65.874 128.7 71.9673C128.7 78.0605 133.634 83 139.721 83Z" fill="black"/>
<path d="M139.721 76.6832C142.323 76.6832 144.432 74.5718 144.432 71.9673C144.432 69.3627 142.323 67.2513 139.721 67.2513C137.119 67.2513 135.01 69.3627 135.01 71.9673C135.01 74.5718 137.119 76.6832 139.721 76.6832Z" fill="#FF862B"/>
<path d="M42.9085 83C48.9955 83 53.9299 78.0605 53.9299 71.9673C53.9299 65.874 48.9955 60.9345 42.9085 60.9345C36.8215 60.9345 31.887 65.874 31.887 71.9673C31.887 78.0605 36.8215 83 42.9085 83Z" fill="black"/>
<path d="M42.9084 76.6832C45.5102 76.6832 47.6195 74.5718 47.6195 71.9673C47.6195 69.3627 45.5102 67.2513 42.9084 67.2513C40.3065 67.2513 38.1973 69.3627 38.1973 71.9673C38.1973 74.5718 40.3065 76.6832 42.9084 76.6832Z" fill="#FF862B"/>
<path d="M125.886 24.6096C127.101 25.0192 128.179 25.7638 128.985 26.769L139.82 41.1153C140.106 41.4876 139.833 42.0337 139.362 42.0337H116.6C115.521 42.0337 114.653 41.165 114.653 40.0853V25.9003C114.653 24.8951 115.472 24.076 116.476 24.076H122.811C123.79 24.076 124.757 24.2373 125.687 24.5476L125.886 24.6096Z" fill="#657595"/>
<path d="M162.322 55.3126V55.9828C162.26 55.9828 162.198 55.9952 162.124 55.9952H157.971C157.115 55.9952 156.409 55.3002 156.409 54.4315C156.409 53.9971 156.582 53.6 156.867 53.327C157.153 53.0415 157.537 52.8678 157.971 52.8678H161.851C162.161 53.6372 162.322 54.4563 162.322 55.3002V55.3126Z" fill="#CE6B29"/>
<path d="M162.322 57.137V59.3708H160.227C159.619 59.3708 159.111 58.8744 159.111 58.2539C159.111 57.9436 159.235 57.6706 159.446 57.472C159.644 57.2611 159.929 57.137 160.239 57.137H162.334H162.322Z" fill="#CE6B29"/>
<path d="M119.798 43.8952H115.645C115.097 43.8952 114.653 44.3397 114.653 44.888V45.5954C114.653 46.1437 115.097 46.5882 115.645 46.5882H119.798C120.346 46.5882 120.79 46.1437 120.79 45.5954V44.888C120.79 44.3397 120.346 43.8952 119.798 43.8952Z" fill="#CE6B29"/>
<path d="M20.3818 24.3117V32.9989H17.9766L14.6788 29.0524V32.9989H11.8274V24.3117H14.2325L17.5303 28.2582V24.3117H20.3818Z" fill="#1E2D4B"/>
<path d="M29.0225 30.8023V33.0113H21.8071V24.3241H28.8614V26.5331H24.6834V27.5508H28.3531V29.6605H24.6834V30.8023H29.0225Z" fill="#1E2D4B"/>
<path d="M29.6055 32.3164L30.5477 30.1818C31.3659 30.6658 32.4569 30.9761 33.4115 30.9761C34.2422 30.9761 34.5397 30.8023 34.5397 30.5045C34.5397 29.4124 29.7418 30.2935 29.7418 27.0544C29.7418 25.4411 31.0932 24.1256 33.7835 24.1256C34.9488 24.1256 36.1514 24.3738 37.0564 24.8826L36.1762 27.0048C35.3208 26.5704 34.5149 26.3595 33.7587 26.3595C32.9032 26.3595 32.6305 26.6077 32.6305 26.9055C32.6305 27.948 37.4284 27.0793 37.4284 30.2811C37.4284 31.8696 36.077 33.2099 33.3867 33.2099C31.9362 33.2099 30.4733 32.85 29.6055 32.3164Z" fill="white"/>
<path d="M46.4541 27.588C46.4541 29.5985 44.9168 30.8643 42.4993 30.8643H41.2471V32.9989H38.3213V24.3117H42.4993C44.9168 24.3117 46.4541 25.5776 46.4541 27.588ZM43.5035 27.588C43.5035 26.9551 43.1192 26.5704 42.3133 26.5704H41.2471V28.6057H42.3133C43.1192 28.6057 43.5035 28.2209 43.5035 27.588Z" fill="white"/>
<path d="M54.6244 30.8023V33.0113H47.4089V24.3241H54.4632V26.5331H50.2852V27.5508H53.9549V29.6605H50.2852V30.8023H54.6244Z" fill="white"/>
<path d="M55.7644 24.3117H60.0416C62.9426 24.3117 64.9387 25.9623 64.9387 28.6553C64.9387 31.3483 62.9426 32.9989 60.0416 32.9989H55.7644V24.3117ZM59.9176 30.7154C61.145 30.7154 61.988 29.9832 61.988 28.6553C61.988 27.3274 61.145 26.5952 59.9176 26.5952H58.6902V30.7154H59.9176Z" fill="white"/>
<path d="M66.0173 24.3117H68.9432V32.9989H66.0173V24.3117Z" fill="white"/>
<path d="M70.0098 28.6553C70.0098 25.9871 72.0554 24.1132 74.8448 24.1132C76.5433 24.1132 77.8823 24.7337 78.7377 25.8506L76.8904 27.5012C76.3821 26.8558 75.7747 26.4835 74.9936 26.4835C73.7786 26.4835 72.9604 27.3274 72.9604 28.6553C72.9604 29.9832 73.7786 30.8271 74.9936 30.8271C75.7747 30.8271 76.3821 30.4548 76.8904 29.8095L78.7377 31.46C77.8823 32.577 76.5433 33.1975 74.8448 33.1975C72.0554 33.1975 70.0098 31.3235 70.0098 28.6553Z" fill="white"/>
<path d="M86.8084 30.8023V33.0113H79.593V24.3241H86.6473V26.5331H82.4693V27.5508H86.139V29.6605H82.4693V30.8023H86.8084Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_234_311">
<rect width="165" height="83" fill="white"/>
</clipPath>
</defs>
</svg>
</template>