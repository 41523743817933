<template>
    <svg width="22" height="43" viewBox="0 0 22 43" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_187_59)">
            <path
                d="M10.9985 43C17.0728 43 21.997 41.5442 21.997 39.7483C21.997 37.9525 17.0728 36.4966 10.9985 36.4966C4.9242 36.4966 0 37.9525 0 39.7483C0 41.5442 4.9242 43 10.9985 43Z"
                fill="#FF862B" />
            <path
                d="M11.0014 0C5.00138 0 0.13916 4.97764 0.13916 11.117C0.13916 12.7247 0.542123 14.12 1.07546 15.6336L10.2458 39.5238C10.5155 40.2276 11.4873 40.2276 11.7569 39.5238L20.9273 15.6275C21.4221 14.2444 21.8606 12.7247 21.8606 11.117C21.8606 4.97764 16.9984 0 11.0014 0ZM11.0014 17.6962C7.45175 17.6962 4.57175 14.7509 4.57175 11.117C4.57175 7.48314 7.45175 4.53781 11.0014 4.53781C14.551 4.53781 17.428 7.48314 17.428 11.117C17.428 14.7509 14.548 17.6962 11.0014 17.6962Z"
                fill="#1E2D4B" />
        </g>
        <defs>
            <clipPath id="clip0_187_59">
                <rect width="22" height="43" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
    
<script>
export default {}
</script>
    
<style></style>