<template>
    <h2 class="mb-4">{{ $t(`dashboard.prefix${model}`) }} {{ item.name }}</h2>
    <div class="col-12 mb-4" v-if="model == 'CityPart'">
        <p class="px-2">{{ $t("dashboard.message")  }}</p>
        <p class="px-2" v-if="message.attachment">{{ $t("dashboard.messageFile")  }}</p>
        <button v-if="message.attachment" class="btn btn-secondary text-nowrap ms-2" type="button"
            @click="downloadMessageFile">{{ $t("dashboard.downloadPDF") }}</button>

        <p class="px-2">{{ $t("dashboard.messageSent")  }}:
          <span class="px-2" v-if="message.sent_at">{{ $t("utils.yes")  }}</span>
          <span class="px-2" v-else>{{ $t("utils.no")  }}</span>
        </p>
    </div>
    <div class="col-12 mb-4">
        <p class="px-2">{{ item.statement ? $t("dashboard.statementUploaded") : $t("dashboard.statementNotUploaded") }}</p>
        <button v-if="item.statement" class="btn btn-secondary text-nowrap ms-2" type="button"
            @click="downloadStatement">{{ $t("dashboard.downloadPDF") }}</button>
        <button v-if="!item.statement" class="btn btn-secondary text-nowrap ms-2" type="button" data-bs-toggle="modal"
            data-bs-target="#uploadStatement">{{ $t("dashboard.uploadPDF") }}</button>
        <button v-if="item.statement" class="btn btn-secondary text-nowrap ms-2" type="button" data-bs-toggle="modal"
            data-bs-target="#deleteStatement">{{ $t("utils.delete") }}</button>
    </div>
    <Popup :header="$t('dashboard.uploadStatement')" :editable="true" id="uploadStatement" @save-data="uploadStatementToServer">
        <template v-slot:editor>
            <input class="form-control" type="file" accept=".pdf" @change="changeStatement">
        </template>
    </Popup>
    <Popup :header="$t('dashboard.deleteStatement')" :body="$t('dashboard.doYouWishToDeleteStatement')" id="deleteStatement"
        :button="$t('utils.delete')" @save-data="deleteStatement" />
</template>

  <script>
  import Popup from "@/components/Popup.vue";

  export default {
    components: {
      Popup,
    },
    props: {
      item: {
        type: Object,
        required: true
      },
      model: {
        type: String,
        required: true
      },
    },
    data() {
      return {
        PDF: null,
        message: {}
      }
    },
    created: function () {
      if (this.model == "CityPart" && this.$store.getters.hasPerm('can_manage_elections')) {
        this.loadMessage()
      }
    },
    methods: {
      uploadStatementToServer: function () {
        let formData = new FormData();
        formData.append('statement', this.PDF);
        this.$store.getters.api.post(`/statement/${this.model}/${this.$route.params.uuid}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(function () {
          this.item.statement = true;
        }.bind(this));
      },
      deleteStatement: function () {
        this.$store.getters.api.delete(`/statement/${this.model}/${this.$route.params.uuid}`
        ).then(function () {
          this.item.statement = false;
        }.bind(this));
      },
      downloadStatement: function () {
        this.$store.getters.api.get(`/statement/${this.model}/${this.$route.params.uuid}`,
          {
            responseType: 'blob'
          }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', this.$route.params.uuid + '.pdf'); //create name
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          });
      },
      downloadMessageFile: function () {
        this.$store.getters.api.get(`/message/${this.$route.params.uuid}/file`,
          {
            responseType: 'blob'
          }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', this.$route.params.uuid + '.pdf'); //create name
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          });
      },
      loadMessage() {
        this.$store.getters.api.get('/message/' + this.$route.params.uuid).then((response) => {
          this.message = response.data;
        }).catch((error) => {
          if (error.response && error.response.data && error.response.data.error_code) {
            alert(this.$t("error." + error.response.data.error_code));
          }
          else {
            alert(this.$t("error.undefined" + ": " + error));
          }
        });
      },
      changeStatement(event) {
        this.PDF = event.target.files[0];
      },
    }
  }
  </script>

  <style lang="scss">
  </style>