<template>
  <nav class="navbar navbar-expand-md fixed-top bg-primary">
    <router-link class="navbar-brand px-4 text-nowrap" to="/dashboard">
      {{ $t("navbar.title") }}
    </router-link>
    <button class="navbar-toggler ms-auto me-4"
    type="button"
    data-bs-toggle="collapse"
    data-bs-target="#navbarNavAltMarkup"
    aria-controls="navbarNavAltMarkup"
    aria-expanded="false"
    aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon navbar-dark"></span>
    </button>
    <div class="collapse navbar-collapse bg-primary" id="navbarNavAltMarkup">
      <div class="navbar-nav ms-auto">
        <router-link v-if="$store.getters.hasPerm('can_create_elections')" class="nav-link px-4 text-nowrap" to="/add-elections">
          {{ $t("navbar.addElections") }}
        </router-link>
        <router-link v-if="$store.getters.hasPerm('can_manage_elections')" class="nav-link px-4 text-nowrap" to="/closing">
          {{ $t("navbar.closing") }}
        </router-link>
        <a class="nav-link px-4 text-nowrap" href="https://volby.tmapy.cz/" target="_blank">
          {{ $t("navbar.okrsek") }}
        </a>
        <router-link class="nav-link px-4 text-nowrap" to="/profile">
          {{ $t("navbar.myProfile") }}
        </router-link>
        <router-link class="nav-link px-4 text-nowrap" to="/logout">
          {{ $t("navbar.logout") }}
        </router-link>
        <ChangeLang class="mx-4 my-2 my-md-1" />
      </div>
    </div>
  </nav>
</template>


<script>
import ChangeLang from "@/components/ChangeLang.vue"
export default {
  components: {
    ChangeLang
  },
  data() {
    return {
    }
  }
}
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/variables";
.navbar-brand {
  font-weight: 600;
  font-size: 28px;
  text-transform: uppercase;
  transition: 0.5s all !important;
}
.nav-link {
  font-weight: 500;
  font-size: 20px;
  transition: 0.5s all !important;
}
.navbar-brand:hover {
  transition: 0.5s all !important;
}
.nav-link:hover {
  transition: 0.5s all !important;
}
</style>