<template>
<svg width="51" height="52" viewBox="0 0 51 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_214_170)">
      <g filter="url(#filter0_d_214_170)">
        <path
          d="M49 26.5C49 32.3371 46.8717 37.6776 43.3471 41.7829C39.1614 46.6719 33.0023 49.8162 26.1046 49.9936C25.9047 50 25.7015 50 25.4984 50C25.2952 50 25.0953 50 24.8921 49.9936C17.9977 49.8162 11.8418 46.6719 7.65612 41.7862C4.1283 37.6776 2 32.3371 2 26.5C2 13.5197 12.5222 3.00003 25.4984 3.00003C38.4746 3.00003 49 13.5197 49 26.5Z"
          fill="#FF862B" />
      </g>
      <path
        d="M25.5019 27.0257C31.3879 27.0257 36.1595 22.2538 36.1595 16.3673C36.1595 10.4809 31.3879 5.70895 25.5019 5.70895C19.6158 5.70895 14.8442 10.4809 14.8442 16.3673C14.8442 22.2538 19.6158 27.0257 25.5019 27.0257Z"
        fill="#1E2D4B" />
      <path
        d="M43.3472 41.7829C39.1616 46.6719 33.0024 49.8162 26.1048 49.9936C25.9048 50 25.7017 50 25.4985 50C25.2954 50 25.0954 50 24.8923 49.9936C17.9979 49.8162 11.8419 46.6719 7.65625 41.7862C7.69817 41.5636 7.74654 41.3443 7.79491 41.1251C9.63944 33.0498 16.866 27.0257 25.4985 27.0257H25.5017C34.1343 27.0257 41.3608 33.0498 43.2086 41.1251C43.2602 41.3443 43.3053 41.5636 43.3472 41.7829Z"
        fill="#1E2D4B" />
      <path
        d="M27.643 43.5728L25.963 46.7009C25.6985 47.1943 24.9794 47.175 24.744 46.6622L23.28 43.4599C23.0543 42.9665 22.9801 42.4182 23.0672 41.8861L24.7731 31.2245L24.0959 29.8668C23.9959 29.6701 24.0378 29.4315 24.1894 29.2767L25.1858 28.2802C25.3632 28.1028 25.6534 28.1028 25.8275 28.2802L26.824 29.2767C26.9788 29.4315 27.0174 29.6669 26.9175 29.8668L26.2467 31.2181L27.9365 41.8378C28.03 42.4311 27.93 43.0439 27.643 43.5728Z"
        fill="#FF862B" />
    </g>
    <defs>
      <filter id="filter0_d_214_170" x="-2" y="-0.999969" width="55" height="55" filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_214_170" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_214_170" result="shape" />
      </filter>
      <clipPath id="clip0_214_170">
        <rect width="51" height="52" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>