<template>
    <select v-if="langs.length > 1" v-model="language" class="form-select w-auto">
        <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
            {{ $t("langs." + lang) }}
        </option>
    </select>
</template>

<script>
export default {
    data() {
        return {
            language: null
        }
    },
    mounted: function () {
        this.language = this.$i18n.locale;
    },
    computed: {
        langs: function () {
            return Object.keys(this.$i18n.messages);
        }
    },
    watch: {
        language: function (v) {
            if (v !== null && v !== undefined) {
                this.$i18n.locale = v;
                localStorage.setItem('lang', v);
            }
        }
    }
}
</script>

<style lang="scss">
</style>