<template>
  <p class="px-2">
    <template v-if="(!member || !member.name) && $store.getters.hasPerm('can_manage_elections')">
      <p>{{ $t("members.registerId") }}</p>
      <input v-model="memberId" class="form-control">
      <button class="btn btn-primary text-white" @click="joinPerson()">
        {{ $t("members.addPersonButton") }}
      </button>
    </template>
    <template v-if="member && member.name && ($store.getters.hasPerm('can_manage_elections') || $store.getters.user.id === member.user)">
      <p>{{ member.name }}</p>
      <button class="btn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#cancelRegistration">
        {{ $t("elections.cancelRegistration") }}
      </button>
    </template>
    <template v-else-if="member_ids && member_ids.id">
      {{ $t("elections.occupied") }}
    </template>
    <template v-else>
      <br>
      <button class="btn btn-primary text-white" data-bs-toggle="modal" data-bs-target="#register">
        {{ $t("elections.register") }}
      </button>
    </template>
  </p>
  <Popup
    :header="$t('elections.register')"
    :editable="false"
    :body="$t('elections.wantToRegister')"
    :button="$t('elections.registerButton')"
    id="register"
    @save-data="register"
  />
  <Popup
    :header="$t('elections.cancelRegistration')"
    :editable="false"
    :body="$t('elections.wantToCancel')"
    :button="$t('elections.cancelButton')"
    id="cancelRegistration"
    @save-data="cancelRegistration"
  />
</template>

<script>
import Popup from '@/components/Popup.vue';

export default {
  components: {
    Popup
  },
  emits: ["reload"],
  props: {
    member_ids: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      member: null,
      memberId: null
    }
  },
  watch: {
    member_ids: {
      immediate: true,
      handler(newVal) {
        if (this.member_ids && this.member_ids.id && (this.$store.getters.hasPerm('can_manage_elections') || this.$store.getters.user.id === this.member_ids.user)) {
          this.loadMember();
        }
      }
    }
  },
  methods: {
    register() {
      this.$store.getters.api.post('/register_committee', { parent: this.$route.params.uuid }).then((response) => {
        this.member = response.data.member;
        this.$emit("reload");
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.error_code) {
          if (error.response.data.error_code === 302) {
            if (confirm(this.$t("error." + error.response.data.error_code))) {
              this.$router.push({ name: "okrsek", params: { uuid: error.response.data?.slug } });
            };
          }
          else {
            alert(this.$t("error." + error.response.data.error_code));
          }
        }
        else {
          alert(this.$t("error.undefined" + ": " + error));
        }
      });
    },
    joinPerson: function () {
      this.$store.getters.api.post('/register_committee', { parent: this.$route.params.uuid, person: this.memberId }).then((response) => {
        this.member = response.data.member;
        this.$emit("reload");
        this.memberId = null;
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.error_code) {
          if (error.response.data.error_code === 302) {
            if (confirm(this.$t("error." + error.response.data.error_code))) {
              this.$router.push({ name: "okrsek", params: { uuid: error.response.data?.slug } });
            };
          }
          else {
            alert(this.$t("error." + error.response.data.error_code));
          }
        }
        else {
          alert(this.$t("error.undefined" + ": " + error));
        }
      });
    },
    cancelRegistration() {
      this.$store.getters.api.delete(`/member/${this.member_ids.id}`).then((_response) => {
        this.member = null;
        this.$emit("reload");
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.error_code) {
          alert(this.$t("error." + error.response.data.error_code));
        }
        else {
          alert(this.$t("error.undefined" + ": " + error));
        }
      });
    },
    loadMember() {
      this.$store.getters.api.get(`/member/${this.member_ids.id}`).then((response) => {
        this.member = response.data.member;
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.error_code) {
          alert(this.$t("error." + error.response.data.error_code));
        }
        else {
          alert(this.$t("error.undefined" + ": " + error));
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
</style>
