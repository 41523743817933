<template>
    <div class="row justify-content-center align-items-center vh-100 w-100 m-0">
        <div class="col-12 row justify-content-center align-items-center p-0">
            <div class="col-12 col-md-10 col-lg-8 col-xxl-6 p-4 mt-4 mb-1 form-box d-flex justify-content-center">
                <h1>{{ $t("login.titleMain") }}</h1>
            </div>
        </div>
        <div v-for="(election, index) in elections" :key="index" class="col-12 row justify-content-center align-items-center p-0">
            <div class="col-12 col-md-10 col-lg-8 col-xxl-6 p-5 my-1 form-box">
                <h2 class="mb-4">{{ election.name }}</h2>
                <h5 class="mb-3">{{ $t("login.term") }}: {{ $d(election.date_from) }} - {{ $d(election.date_to) }}</h5>
                <h5>{{ $t("login.closing") }} {{ $d(election.date_closing) }}</h5>
            </div>
        </div>
        <div class="col-12 row justify-content-center align-items-center p-0">
            <div class="col-12 col-md-10 col-lg-8 col-xxl-6 p-5 mb-4 mt-1 form-box">
                <div class="d-flex flex-wrap justify-content-between">
                    <h2>{{ $t("login.title2") }}</h2>
                    <ChangeLang class="mb-2"/>
                </div>
                <p class="mt-2">{{ $t("login.info") }}</p>
                <button type="submit"
                class="p-2 mt-2 btn btn-lg btn-primary w-100 form-submit"
                @click="signUp()"
                >
                    {{ $t("login.register") }}
                </button>
                <div class="d-flex flex-wrap justify-content-between mt-5">
                    <h2>{{ $t("login.title") }}</h2>
                </div>
                <button type="submit"
                class="mt-3 p-2 btn btn-lg btn-primary w-100 form-submit"
                @click="login()"
                >
                    {{ $t("login.login") }}
                </button>
                <button type="submit"
                class="mt-3 mb-5 p-2 btn btn-lg btn-primary w-100 form-submit"
                @click="login()"
                >
                    {{ $t("login.login2") }}
                </button>
                <p>{{ $t("login.contact", {email: "okrsky@svobodni.cz", phone: "773 697 986"}) }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import ChangeLang from "@/components/ChangeLang.vue"
import { CONFIG } from "@/config.js"
export default {
    components: {
        ChangeLang
    },
    data() {
        return {
            elections: []
        }
    },
    created() {
        this.loadElections();
    },
    methods: {
        login: function () {
            const params = {
                client_id: CONFIG.registerOauthClientId,
                response_type: "code",
                scope: "read",
                redirect_uri: CONFIG.vueAppUrl + "/oauth/callback"
            }
            if (this.$route.query?.path) {
                this.$cookies.set("pathRedirect", this.$route.query.path);
            }
            const queryString = Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&');
            window.location.href = CONFIG.api.registerUrl + "/oauth/authorize/?" + queryString;
        },
        signUp: function () {
            window.location.href = CONFIG.registerVueAppUrl + "/sign_up_elections?return_url=" + CONFIG.vueAppUrl + "/thankyou";
        },
        loadElections() {
            this.$store.getters.api.get('/election_list').then((response) => {
                this.elections = response.data.items;
            }).catch((error) => {
                if (error.response && error.response.data && error.response.data.error_code) {
                    alert(this.$t("error." + error.response.data.error_code));
                }
                else {
                    alert(this.$t("error.undefined" + ": " + error));
                }
            });
        }
    }
}
</script>

<style lang="scss">
</style>